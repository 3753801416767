import instance from "../Config/Config"


export const GOLD_PLANT_SCHEME ="get_gold_plant"
export const Gold_PLAN_PURCHASE ="gold_plant_purchase"
export const GET_Gold_PLAN_LIST ="get_gold_plant_purchase"

export const GoldPlant_fun = async ()=>{
      const response =  await instance.get(`${GOLD_PLANT_SCHEME}`)
      return response?.data
} 

export const SaveGoldplan = async(value)=>{
    const response = await instance.post(`${Gold_PLAN_PURCHASE}`,value)
    return response?.data
}

export const GetGoldPlantApi = async (slug)=>{
    const response =  await instance.get(`${GET_Gold_PLAN_LIST}?plan_status=${slug}`)
    return response?.data
} 
