import instance from '../Config/Config';



export const UPDATE_URL = "edit-profile"
export const ACCOUN_DETAILS_URL = "account_details"
// export const KYC_URL = "kyc"
// export const UPDATE_KYC_URL = "edit_kyc"
export const NOTIFICATION  = "notifications"
export const PROFILE_URL = "get_profile"
 

export const update_fun = async (value) => {
    const response = await instance.post(`${UPDATE_URL}`, value);
    return response.data
}

export const accountdetails_fun = async (value) => {
    const response = await instance.post(`${ACCOUN_DETAILS_URL}`, value)
    return response.data

}

export const getprofile_fun = async()=>{
    const response = await instance.get(`${PROFILE_URL}`)
    return response?.data
}

// export const kyc_fun = async (value) => {
//     const response = await instance.post(`${KYC_URL}`, value)
//     return response.data

// }

// export const editkyc_fun = async (value) => {
//     const response = await instance.post(`${UPDATE_KYC_URL}`, value)
//     return response.data

// }

export const notification_fun = async()=>{
    const response = await instance.get(`${NOTIFICATION}`)
    return response?.data
}



