import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useNavigate } from "react-router-dom";
import { Logindetail } from "../context/Logindetail.js";
import * as Yup from 'yup'
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { editkyc_fun, update_fun, } from "../api/Dashboardapi.js";

const Kyc = ({ settings }) => {

    const [spinner, setspinner] = useState(false)


    const { token, detail_info, setkyc_detail, kyc_detail, setdetail_info } = useContext(Logindetail)

    const [initValue, setinitValue] = useState({
        "name_as_aadhar": `${detail_info?.name_as_aadhar ? detail_info?.name_as_aadhar : ''}`,
        "aadhar_number": `${detail_info?.aadhar_number ? detail_info?.aadhar_number : ''}`,
        "name_as_pan": `${detail_info?.name_as_pan ? detail_info?.name_as_pan : ''}`,
        "pan_number": `${detail_info?.pan_number ? detail_info?.pan_number : ''}`,
        "verified": detail_info?.verified ? detail_info?.verified : 1,
        "pan_documents": `${detail_info?.pan_documents ? detail_info?.pan_documents : ''}`,
        "aadhar_documents": `${detail_info?.aadhar_documents ? detail_info?.aadhar_documents : ''}`
    })

    const updateschema = Yup.object().shape({

        "name_as_aadhar": Yup.string()
            .required("Enter Name as per Aadhar")
            .matches(/^[a-zA-Z ]*$/, "Invalid Aadhar name"),

        "aadhar_number": Yup.string().required("Enter Aadhar Number")
            .min(12, 'Min 12 digits')
            .max(12, 'Max 12 digits')
            .matches(/^[0-9]+$/, "Invalid  Aadhar number"),

        "name_as_pan": Yup.string().required("Enter Name as per PAN")
            .matches(/^[a-zA-Z ]*$/, "Invalid Pan name"),

        "pan_number": Yup.string().required("Enter PAN number")
            .min(10, 'Min 10 digits')
            .max(10, 'Max 10 digits')
            .matches(/^[0-9]+$/, "Invalid Pan number"),
            
        "pan_documents": Yup.mixed(),

        "aadhar_documents": Yup.mixed(),

    })

    const Formrik = useFormik({
        initialValues: initValue,
        validationSchema: updateschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            setspinner(true)

            update_fun(value)

                .then((res) => {
                    console.log(res)
                    toast.success('Kyc updated successfully')
                    localStorage.setItem('green_login', JSON.stringify(res?.user))
                    setdetail_info(res?.user)
                    setspinner(false)
                })
                .catch((err) => {
                    console.log(err)
                    if (err?.response?.data?.status === 400) {
                        toast.error(err?.response?.data?.message);
                    }
                    setspinner(false)

                });
        }
    });

    const handleaadhar = (e)=>{

    }

    const handlepan =(e)=>{

    }


    return (
        <>
            <HeadingBanner title={"KYC"} />
            <section>
                <div className="kyc-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-5">
                                <Dashboard setting_data={settings} />
                            </div>
                            <div className="col-xl-9 col-lg-7">
                                <div className="kyc-content">
                                    <div className="bd-heads pb-3" >
                                        <h4>KYC</h4>
                                    </div>
                                    <div className="bd-form border">
                                        <form onSubmit={Formrik.handleSubmit}>
                                            <div className="row">
                                                <div className="form-group col-md-6 col-sm-6 login-text">
                                                    <label>Name as Aadhar Card</label>
                                                    <input type="text" className="form-control" id="inputaddress" placeholder="Name as Aadhar Card" {...Formrik.getFieldProps("name_as_aadhar")} />
                                                    {
                                                        Formrik.touched.name_as_aadhar && Formrik.errors.name_as_aadhar ? (<p className="text-danger">{Formrik.errors.name_as_aadhar}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6  login-text">
                                                    <label>Name As Pan Card</label>
                                                    <input type="text" className="form-control" id="inputaddress2" placeholder="Name As Pan Card" {...Formrik.getFieldProps("name_as_pan")} />
                                                    {
                                                        Formrik.touched.name_as_pan && Formrik.errors.name_as_pan ? (<p className="text-danger m-0">{Formrik.errors.name_as_pan}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6  login-text">
                                                    <label>Aadhar Number</label>
                                                    <input type="text" className="form-control" id="inputaddress2" placeholder="Aadhar Number"  {...Formrik.getFieldProps("aadhar_number")} />
                                                    {
                                                        Formrik.touched.aadhar_number && Formrik.errors.aadhar_number ? (<p className="text-danger m-0">{Formrik.errors.aadhar_number}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6  login-text">
                                                    <label>Pan Number</label>
                                                    <input type="text" className="form-control" id="inputaddress2" placeholder="Pan Number" {...Formrik.getFieldProps("pan_number")} />
                                                    {
                                                        Formrik.touched.pan_number && Formrik.errors.pan_number ? (<p className="text-danger m-0">{Formrik.errors.pan_number}</p>) : null
                                                    }
                                                </div>
                                                <div className="form-group mb-3 col-md-6 col-12 login-text">
                                                    <label>Upload Aadhar Documents *(PNG,JPG,JPEG)</label>
                                                    <input className="form-control" id="inputGroupFile000" type="file" accept="image/jpg, image/jpeg, image/png" multiple="multiple" onChange={handleaadhar} />
                                                    {
                                                        Formrik.touched.aadhar_documents && Formrik.errors.aadhar_documents ? (<p className="text-danger m-0">{Formrik.errors.aadhar_documents}</p>) : null
                                                    }

                                                </div>

                                                <div className="form-group mb-3 col-md-6 col-12 login-text">
                                                    <label>Upload Pan Documents *(PNG,JPG,JPEG)</label>
                                                    <input className="form-control" id="inputGroupFile0001" accept="image/jpg, image/jpeg, image/png" multiple="multiple" type="file" onChange={handlepan} />
                                                    {
                                                        Formrik.touched.pan_documents && Formrik.errors.pan_documents ? (<p className="text-danger m-0">{Formrik.errors.pan_documents}</p>) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="update-profile  mx-3">
                                                <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Update'}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Kyc;