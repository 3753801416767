import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Login from '../Components/Login/Login.jsx';
import Signup from '../Components/Signup/Signup.jsx';
import Verification from '../Components/Signup/Verifycation.jsx';
import { useContext } from 'react';
import { Logindetail } from '../context/Logindetail.js';
import Logout from '../Components/Login/Logout.jsx';
import { Forgatpwd } from '../Components/Login/Forgatpwd.jsx';


export default function Headers({setting_datas , openScheme}) {


     const {pathname} = useLocation(); 
    const { detail_info,token} = useContext(Logindetail);
  
    return (
        <>
            <header id="sticky" className='sticky' >
                <div className='header'>
                    <div className='top_nav'>
                        <div className='container'>
                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div className=' d-block d-sm-flex align-items-center justify-content-md-start  justify-content-sm-center  gap-sm-1 gap-md-3'>
                                    <div className='d-flex align-items-center justify-content-start gap-2'>
                                        <img src='/assets/iocns/call.png' alt='' />
                                        <a className='text-white m-0' href={`tel:+${setting_datas[0]?.phone}`} data-rel="external"  rel="noreferrer"><h6 className='text-wrap lh-base m-0'>{setting_datas[0]?.phone}</h6></a>
                                    </div>
                                    <div className='line d-none d-sm-block'></div>
                                    <div className='d-flex align-items-center justify-content-start gap-2'>
                                        <img src='/assets/iocns/Mail.png' alt='' />
                                        <a className='text-white' href={`mailto:${setting_datas[0]?.mail_host}`} data-rel="external" rel="noreferrer"  ><h6 className='text-wrap lh-base m-0'>{setting_datas[0]?.mail_host}</h6></a>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center justify-content-start gap-md-3 gap-sm-2 gap-2 '>
                                    <a href={setting_datas[0]?.facebook} target='_blank' rel="noreferrer" > <img src='/assets/iocns/Facebook.png' alt='facebook_img' /> </a>
                                    <a href={setting_datas[0]?.instagram} target='_blank'  rel="noreferrer">  <img src='/assets/iocns/insta.png' alt='insta_img' /> </a>
                                    <a href={setting_datas[0]?.twitter} target='_blank'  rel="noreferrer"><img src='/assets/iocns/X.png' alt='X_img' /></a>
                                    <a href={setting_datas[0]?.youtube} target='_blank'  rel="noreferrer" >    <img src='/assets/iocns/Youtube.png' alt='youtube_img' /></a>
                                    <a href={setting_datas[0]?.linkedin} target='_blank'  rel="noreferrer">    <img src='/assets/iocns/Linkedin.png' alt='linkedin_img' /> </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="navbar navbar-expand-lg  bottom_nav">
                        <div className="container">
                            <Link to="/"> <img src='/assets/images/green.png' className='mainIcon' alt='greenheap' /></Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarScroll">
                                <ul className="navbar-nav  mx-auto my-2 my-lg-0 navbar-nav-scroll">
                                    <li className="nav-item">
                                        <Link to={'/'} className={pathname == '/' ? "nav_active nav-link" : "nav-link"} aria-current="page" >Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/about'} className={pathname == '/about' ? "nav_active nav-link" : "nav-link"} >About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/scheme'} className={pathname == '/scheme' ? "nav_active nav-link" : "nav-link"} >GreenHeap Scheme</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/gold-silver'} className={pathname == '/gold-silver' ? "nav_active nav-link" : "nav-link"} >Buy Gold & Silver</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/contact'} className={pathname == '/contact' ? "nav_active nav-link" : "nav-link"} >Contact Us</Link>
                                    </li>
                                </ul>
                                {
                                    token && detail_info ? (
                        
                                        <div className="dropdown show top-drop">
                                            <a className="border-0 gap-2 btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img alt='' src={detail_info?.avatar ?? '/assets/images/user.jfif'} onError={(e) => e.currentTarget.src = '/assets/images/user.jfif'} />
                                            <span className='mx-2 fw-bold text-uppercase'>{detail_info?.name}</span>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="/user/dashboard" >Dashboard</Link></li>
                                                <li><Link className="dropdown-item" to="/user/account-setup" >My Account</Link></li>
                                                <li><Link className="dropdown-item" to="" data-bs-toggle="modal" data-bs-target="#logout_modal">Logout</Link></li>
                                            </ul>
                                        </div>

                                    ) :
                                        (<div className='d-flex align-items-center  gap-1'>
                                            <button type='button' className='btn btn_sig' id='loginPopupopen' data-bs-toggle="modal" data-bs-target="#loginmodalview"  >
                                                LOGIN
                                            </button>

                                            <button type='button' className='btn  btn_sig ' data-bs-toggle="modal" data-bs-target="#signupmodalview">
                                                SIGNUP
                                            </button>
                                        </div>)
                                }

                            </div>

                        </div>
                    </nav>
                </div>



            </header>

            <Login openSchemes={openScheme}/>

            <Signup />

            <Logout />

            <Forgatpwd />

        </>


    )
}