import React, { useCallback, useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useNavigate } from "react-router-dom";
import { Logindetail } from "../context/Logindetail.js";
import { Cancel_scheme, Getdigital_trans, GetPlanbenefit, PayDaily_Scheme, Post_PlanActive, Savedigitalplan } from "../api/RouteScheme2.js";
import moment from "moment";
import toast from "react-hot-toast";
import useRazorpay from "react-razorpay";
import { Shimmer } from "react-shimmer";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { SchemeDetails } from "../context/SchemeDetails.js";
import { BookmarkCheckFill, BookmarkStarFill, BookmarkXFill, PauseCircleFill, SlashCircleFill, X } from "react-bootstrap-icons";
const Transaction = ({ settings }) => {

    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const { detail_info } = useContext(Logindetail)
    const { schemelist } = useContext(SchemeDetails)
    const [ALlData_list, setALlData_list] = useState([])
    const [sort, setsort] = useState(5)
    const [PlanActive, setPlanActive] = useState()
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemaData, setshemaData] = useState()
    const [price, setprice] = useState()
    const [isloading, setisloading] = useState(true)
    // const [Entername, setEntername] = useState()
    const [spinner, setspinner] = useState(false)
    const [Transaction, setTranscation] = useState([])
    const [Transname, setTransname] = useState()
    const [spinid, setspinid] = useState()
    const [planbenifit, setplanbenifit] = useState()
    const [BenifitAmt, setBenifitAmt] = useState()
    const [checked, setchecked] = useState()
    const [spinner1, setspinner1] = useState()
    const [activebank, setactivebank] = useState()
    const [cancel_slug, setcancel_slug] = useState()
    const [finalamount, setfinalamount] = useState()
    const [plandetails, setpladetails] = useState()
    const [statuslist, setstatuslist] = useState({
        'active': '',
        'hold': '',
        'complete': '',
        'suspend': '',
        'cancel': ''
    })



    const getTransaction = async (slug) => {
        setisloading(true)
        Getdigital_trans(slug)
            .then((res) => {
                setALlData_list(res?.data)
                setactivebank(res?.bank_details)
                setcancel_slug(res?.cancel_slug)
                setpladetails(res?.plan)
                setisloading(false)
                setstatuslist(previousState => {
                    return { ...previousState, active: res?.active_count, hold: res?.hold_count, complete: res?.complete_count, suspend: res?.suspended_count, cancel: res?.cancel_count }
                })
                setfinalamount(res?.final_amount)
                if (Math.floor(parseInt(res?.data[0]?.total_gram)) >= 1 && res?.data[0]?.plan_active === 0 && res?.data[0]?.cancel_status === 0) {
                    getactive(res?.data[0]?.id)
                }
            })
            .catch((err) => {
                console.log(err)
                setisloading(false)
            })

    }

    const getactive = async (id) => {
        document.getElementById('activepopmsg').click()
        setPlanActive(id)
    }


    const handleactive = () => {
        Post_PlanActive(PlanActive)
            .then((res) => {
                getTransaction()
                toast.success(res?.data)
                setPlanActive()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handlePayment = useCallback(async () => {
        setspinner(true)
        const options = {
            key: RAZAR_KEY,
            amount: (price) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {


                if (ALlData_list.length >= 1) {

                    const body = {
                        "amount": price,
                        "purchase_id": shemaData?.id,
                        "transaction_id": res?.razorpay_payment_id,
                    }

                    PayDaily_Scheme(body).then(res => {
                        toast.success("Payment success!")
                        setShowRazorpayModal(false);
                        setspinner(false)
                        getTransaction()

                    }).catch(err => {
                        console.log('err', err.message)
                        setspinner(false)
                    })
                }

                else {

                    const body = {
                        "digital_plan_id": shemaData?.id,
                        "amount": price,
                        "transaction_id": res?.razorpay_payment_id,
                    }
                    Savedigitalplan(body).then(res => {
                        if (res?.error) {
                            return toast.error(res?.error ?? 'Somthing Went Wrong!')
                        }
                        toast.success(`Payment successful from ${plandetails?.name}`)
                        getTransaction()

                        setShowRazorpayModal(false);
                    }).catch(err => {
                        console.log('err', err.message)
                    })
                }


            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemaData]);



    const openrazar_function = () => {
        handlePayment()
    }

    const router = useNavigate()


    const PostCancel_id = (id) => {

        if (!checked) {
            toast.error('Please Agree Terms & Condition')
        }
        else {

            document.getElementById('cancelpopmsg').click()
            Cancel_scheme(id)
                .then((res) => {
                    toast.success(res?.data)
                    getTransaction()
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    const handlespinner = (data) => {
        setspinid(data?.id)
        setspinner(true)
    }

    const handledownload = (value) => {
        const baseurl = process.env.REACT_APP_BASE_URL
        let fileName = `${moment(value?.created_at).format('DD-MM-YYYY')}-invoice.pdf`;
        const url = `${baseurl}digital_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
                setspinid()
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
                setspinner(false)
                setspinid()
            });
    }

    const schemebeniftapi = () => {
        setspinner1(true)

        GetPlanbenefit()
            .then((res) => {
                console.log(res)
                setplanbenifit(res?.months)
                setspinner1(false)
            })
            .catch((err) => {
                console.log(err)
                setspinner(false)
            })
    }

    console.log("schemelisgt", ALlData_list.length)

    const columns = [

        { field: 's_no', headerName: 'S.No', width: 65 },
        // {
        //     field: 'name',
        //     headerName: 'Name',
        //     width: 120,
        //     // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
        //     valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
        // },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150,
            valueGetter: (value, row) => `${row?.gold_gram ? row?.gold_gram : '0'}(gm)`,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 150,
            valueGetter: (value, row) => `${row?.amount ? row?.amount : '-'}`,
        },
        {
            field: 'purchase_Date',
            headerName: 'Purchased Date',
            width: 160,
            valueGetter: (value, row) => `${row?.paid_date ? moment(row?.paid_date).format('DD-MM-YY') : '-'}`,
        },

        {
            field: 'Activated_Date',
            headerName: 'Activated Date',
            valueGetter: (value, row) => `${row?.active_date ? moment(row?.active_date).format('DD-MM-YY') : '-'}`,
            width: 160,
        },
        // {
        //     field: 'withdraw_date',
        //     headerName: 'Withdraw Date',
        //     width: 100,
        //     valueGetter: (value, row) => `${row?.withdraw_date ? row?.withdraw_date : '-'}`,
        //     sortable: false,
        // },
        {
            field: 'view',
            headerName: 'View',
            width: 140,
            sortable: false,
            renderCell: (params) => (
                <>
                    {/* <img
                        src="\assets\iocns\eye.png"
                        // onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row?.purchase_transaction)}
                        alt=""
                        width={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => params.row?.cancel_status == 1 ? {} : handleTransaction(params?.row) }

                    /> */}

                    {
                        spinner1 ? <span className="spinner-border spinner-border-sm mx-2 " role="status"></span> :
                            <img
                                src="\assets\iocns\eye.png"
                                onClick={() => { ALlData_list[0]?.cancel_status === 1 ? toast.error('Plan is cancelled') : schemebeniftapi(); setBenifitAmt(params?.row?.amount); }}
                                alt=""
                                width={20}
                                style={{ cursor: 'pointer' }}
                            />
                    }

                </>
            )
        },

        {
            field: 'invoice',
            headerName: 'Invoice',
            width: 140,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img alt='' src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 120,
        //     renderCell: (params) => (
        //         <>
        //             {
        //                 params?.row?.status === 1 ? <button className="btn bg-success  gold-activebtn text-white  border-0" disabled>Completed</button> : params?.row?.cancel_status === 1 ? <button className="btn bg-secondary gold-activebtn text-white  border-0" disabled>InActive</button> : params?.row?.plan_active === 1 ? <button className="btn border-0 bg-primary text-white gold-activebtn" disabled={true}>Actived</button> : Math.floor(parseInt(params?.row?.total_gram)) >= 1 ? <button className="btn bg-warning gold-activebtn border-0  text-white" onClick={() => { getactive(params?.row?.id) }}>Active</button> : <button className="btn bg-secondary  gold-activebtn text-white  border-0" disabled>InActive</button>

        //             }

        //         </>
        //     ),
        //     sortable: false,
        // },
        // {
        //     field: 'payment',
        //     headerName: 'Payment',
        //     width: 120,
        //     renderCell: (params) => (
        //         <>
        //             {params?.row?.cancel_status === 1 || params?.row?.status === 1 ? <button className="btn  paynsd2  border-0 text-white" >PAY NOW</button> : <button className="btn paynsd text-white" onClick={() => { setshemaData(params?.row); document.getElementById('openPriceplanpopu').click() }}>PAY NOW</button>}
        //         </>
        //     ),
        //     sortable: false,
        // },
        // {
        //     field: 'cancel',
        //     headerName: 'Cancel',
        //     width: 120,
        //     renderCell: (params) => (
        //         <>

        //             {
        //                 params?.row?.status === 1 ? <button className="btn bg-secondary  gold-cancellbtn border-0 text-white" disabled={true} >Cancel</button> : params?.row?.cancel_status === 1 ? <button className="btn bg-danger border-0 text-white gold-cancellbtn" disabled={true}>Cancelled </button> : moment(params?.row?.created_at, 'YYYY-MM-DD').diff(new Date(), 'days').toString().split('-')[1] >= 90 ? <button className="btn bg-danger border-0 gold-cancellbtn text-white" onClick={() => { PostCancel_id(params?.row?.id) }} >Cancel</button> :
        //                     <button className="btn bg-secondary gold-cancellbtn border-0 text-white" disabled={true}  >Cancel</button>

        //             }
        //         </>
        //     ),
        //     sortable: false,
        // }
    ];

    // const columns2 = [

    //     { field: 's_no', headerName: 'S.No', width: 70 },
    //     {
    //         field: 'name',
    //         headerName: 'Name',
    //         width: 120,
    //         // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
    //         valueGetter: (value, row) => `${Transname}`,
    //     },
    //     {
    //         field: 'weight',
    //         headerName: 'Weight',
    //         width: 120,
    //         valueGetter: (value, row) => `${row?.gold_gram ? row?.gold_gram : '0'}(gm)`,
    //     },
    //     {
    //         field: 'total',
    //         headerName: 'Total',
    //         width: 100,
    //         valueGetter: (value, row) => `${row?.amount ? row?.amount : '-'}`,
    //     },
    //     {
    //         field: 'paid_Date',
    //         headerName: 'Paid Date',
    //         width: 120,
    //         valueGetter: (value, row) => `${row?.paid_date ? moment(row?.paid_date).format('DD-MM-YYYY') : '-'}`,
    //     },
    //     {
    //         field: 'transaction_id',
    //         headerName: 'Transaction ID',
    //         width: 235,
    //         valueGetter: (value, row) => `${row?.transaction_id ? row?.transaction_id : '-'}`

    //     },

    // {
    //     field: 'invoice',
    //     headerName: 'Invoice',
    //     width: 90,
    //     renderCell: (params) => (
    //         <>
    //             {
    //                 spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)

    //             }
    //         </>
    //     ),
    //     sortable: false,
    // }
    // ];


    const paginationModel = { page: 0, pageSize: 5 };

    const handleTransaction = (value) => {
        setTranscation(value?.digital_transaction)
        setTransname(value?.beneficiary?.name ? value?.beneficiary?.name : detail_info?.name)
    }


    useEffect(() => {
        getTransaction(1)
    }, [])

    console.log('kkkk', shemaData)
    return (
        <>
            <HeadingBanner title={isloading ? 'Loading...' : settings ? settings[0]?.digital_plan : 'Scheme'} />
            <section>

                <div className="container">
                    <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

                    <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{`${shemaData?.digital_plan?.name ?? shemaData?.name}(Cash Back)`}</h1>

                                    <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }}></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!price) {
                                            toast.error("Please Enter Price!")
                                        } else
                                            if (parseInt(price) < parseInt(shemaData?.digital_plan?.min_price ?? shemaData?.min_price)) {
                                                toast.error(`Minimum Amount is ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price}!`)
                                            } else if (parseInt(price) > shemaData?.digital_plan?.max_price ?? shemaData?.max_price) {
                                                toast.error(`Maximum Amount is ${shemaData?.digital_plan?.max_price ?? shemaData?.max_price}!`)
                                            } else {
                                                openrazar_function()
                                                document.getElementById('closepriceModal')?.click();
                                                document.getElementById('Rupees').value = null
                                            }
                                    }}>
                                        <div className="mb-3">
                                            <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price} to Maximum Amount ${shemaData?.digital_plan?.max_price ?? shemaData?.max_price})`}</small></label>
                                            <input type="text" maxLength={10} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemaData?.digital_plan?.min_price ?? shemaData?.min_price}`} />
                                        </div>
                                        <div className="mb-3">
                                            <div className="update-profile  mx-2">
                                                <button className="text-white" type="submit">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-8">
                                <div className="siptrans-content">
                                    <div className="siptrans-head pb-3">
                                        {
                                            isloading ? <h4><Shimmer className="rounded " width={180} height={30} /></h4> :
                                                <div className="d-flex align-items-center gap-2">
                                                    <h4> {planbenifit ? <img src="/assets/iocns/Back.png" className="cursor_pointer" alt="" onClick={() => setplanbenifit(null)} style={{ width: '30px' }} /> : ''} {ALlData_list[0]?.digital_plan?.name ? ALlData_list[0]?.digital_plan?.name : plandetails?.name}</h4>
                                                    {
                                                        ALlData_list[0]?.plan_active === 1 ? <h6 className="active-status">Activated</h6> : ''
                                                    }
                                                </div>
                                        }
                                    </div>
                                    {
                                        isloading ? (

                                            <>
                                                <div className="row">
                                                    <div className="cox-xl-33">
                                                        <div className="plan-viewcard comp status2">
                                                            <div className="plan-count">
                                                                <h4>Actived</h4>
                                                                <h2>0</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkCheckFill />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cox-xl-33">
                                                        <div className="plan-viewcard comp status1">

                                                            <div className="plan-count ">
                                                                <h4>Completed</h4>
                                                                <h2>0</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkStarFill />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cox-xl-33">

                                                        <div className="plan-viewcard comp status3">
                                                            <div className="plan-count">
                                                                <h4>Hold</h4>
                                                                <h2>0</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <PauseCircleFill />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="cox-xl-33">
                                                        <div className="plan-viewcard comp status4">
                                                            <div className="plan-count">
                                                                <h4>Cancelled</h4>
                                                                <h2>0</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <BookmarkXFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cox-xl-33">
                                                        <div className="plan-viewcard comp status5">
                                                            <div className="plan-count">
                                                                <h4>Suspended</h4>
                                                                <h2>0</h2>
                                                            </div>
                                                            <div className="icon-position">
                                                                <SlashCircleFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pay-canc d-flex gap-2 align-items-center mt-3">
                                                    <div><Shimmer width={150} height={40} className="rounded"/></div>
                                                    <div><Shimmer width={90} height={40} className="rounded"/></div>
                                                    <div><Shimmer width={90} height={40} className="rounded"/></div>
                                                </div>
                                                <div className="siptrans-table border mt-4" >
                                                    <div className="siptable-view">
                                                        <table className="table">
                                                            <thead className="p-3">
                                                                <tr className="text-center">
                                                                    <th scope="col" >S.NO</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th scope="col">Purchased Date</th>
                                                                    <th scope="col">Activated Date</th>
                                                                    <th scope="col">View</th>
                                                                    <th scope="col">Invocie</th>

                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Array.from({ length: 6 }).map((_, i) => {
                                                                        return (
                                                                            <tr className="text-center" key={i}>
                                                                                <td className="text-center"><Shimmer height={25} width={70} className="rounded d-inline-block ms-2" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={70} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={150} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={150} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>


                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </>

                                        ) :


                                            planbenifit ? (
                                                <div className="schematable">
                                                    <div className='d-flex gap-3'>
                                                        <div>
                                                            <div className='mb-2 leaft2 text-center G1'>
                                                                <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center  G2'>
                                                                <h5>3 months (0-90 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G3'>
                                                                <h5>6 months (90-180 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G4'>
                                                                <h5>9 months (180 - 270 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G5'>
                                                                <h5>12 months (270 - 360 days)</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G6'>
                                                                <h5>2nd Year</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center G7'>
                                                                <h5>3rd Year</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center C1'>
                                                                <h5>GreenHeap Gold approx. ( {`Rs. ${BenifitAmt} `} )</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2 '>
                                                                <h5> {`(${parseFloat(planbenifit?.three_months.gold) + '%' + 'X' + planbenifit?.three_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.six_months.gold) + '%' + 'X' + planbenifit?.six_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.nine_months.gold) + '%' + 'X' + planbenifit?.nine_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.one_year.gold) + '%' + 'X' + planbenifit?.one_year?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.two_years.gold) + '%' + 'X' + planbenifit?.two_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.three_years.gold) + '%' + 'X' + planbenifit?.three_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center C1'>
                                                                <h5>GreenHeap Silver Bonus</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2 '>
                                                                <h5> {`(${parseFloat(planbenifit?.three_months.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.three_months?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.six_months.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.six_months?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.nine_months.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.nine_months?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.one_year.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.one_year?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.two_years.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.two_years?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${parseFloat(planbenifit?.three_years.silver) + '%' + ' ' + 'per annum' + ' ' + 'For' + ' ' + planbenifit?.three_years?.months + 'm'})`} Rs. {Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2)}</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center C1'>
                                                                <h5>GreenHeap Gold + Silver Bonus</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5> {`(${Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2))}  </h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center C2'>
                                                                <h5>{`(${Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2) + ' + ' + Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className='mb-2 leaft2 text-center F1'>
                                                                <h5 className='text-light'>Sip Total Returns</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F2'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F3'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F4'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F5'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F6'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>

                                                            <div className='mb-2 leaft2 text-center F7'>
                                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number(((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt) / 12).toFixed(2))}</h5>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ) :

                                                statuslist?.active > 0 ? (

                                                    <>

                                                        <div className="row">
                                                            <div className="cox-xl-33">
                                                                <div className="plan-viewcard comp status2" onClick={() => { getTransaction(1) }}>
                                                                    <div className="plan-count">
                                                                        <h4>Actived</h4>
                                                                        <h2>{statuslist?.active ? statuslist?.active : 0}</h2>
                                                                    </div>
                                                                    <div className="icon-position">
                                                                        <BookmarkCheckFill />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cox-xl-33">
                                                                <div className="plan-viewcard comp status1" onClick={() => { getTransaction(3) }}>

                                                                    <div className="plan-count ">
                                                                        <h4>Completed</h4>
                                                                        <h2>{statuslist?.complete ? statuslist?.complete : 0}</h2>
                                                                    </div>
                                                                    <div className="icon-position">
                                                                        <BookmarkStarFill />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cox-xl-33">

                                                                <div className="plan-viewcard comp status3" onClick={() => { getTransaction(4) }}>
                                                                    <div className="plan-count">
                                                                        <h4>Hold</h4>
                                                                        <h2>{statuslist?.hold ? statuslist?.hold : 0}</h2>
                                                                    </div>
                                                                    <div className="icon-position">
                                                                        <PauseCircleFill />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="cox-xl-33">
                                                                <div className="plan-viewcard comp status4" onClick={() => { getTransaction(2) }}>
                                                                    <div className="plan-count">
                                                                        <h4>Cancelled</h4>
                                                                        <h2>{statuslist?.cancel ? statuslist?.cancel : 0}</h2>
                                                                    </div>
                                                                    <div className="icon-position">
                                                                        <BookmarkXFill />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cox-xl-33">
                                                                <div className="plan-viewcard comp status5" onClick={() => { getTransaction(5) }}>
                                                                    <div className="plan-count">
                                                                        <h4>Suspended</h4>
                                                                        <h2>{statuslist?.suspend ? statuslist?.suspend : 0}</h2>
                                                                    </div>
                                                                    <div className="icon-position">
                                                                        <SlashCircleFill />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="d-flex align-items-center gap-3 my-3">
                                                            <h5 className="m-0">Welcome <span className="loginname">{detail_info?.name}</span> !</h5>

                                                            {
                                                                ALlData_list[0]?.cancel_status == '1' ? <button className="btn paynsd2 secondplan-btn  border-0 text-white" >PAY NOW</button> :
                                                                    <button className="btn paynsd secondplan-btn" type="button" onClick={() => { setshemaData(ALlData_list[0]); document.getElementById('openPriceplanpopu').click() }}>PAY NOW</button>
                                                            }

                                                            {
                                                                ALlData_list[0]?.cancel_status == '1' ? <button className="btn btn-danger secondplan-btn" disabled={true}>CANCELLED</button> : <button className="btn btn-secondary text-white secondplan-btn" onClick={() => { document.getElementById('cancelpopmsg').click() }}>CANCEL</button>
                                                            }

                                                        </div>




                                                        <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                            <DataGrid
                                                                rows={ALlData_list[0]?.digital_transaction.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                                columns={columns}
                                                                initialState={{ pagination: { paginationModel } }}
                                                                pageSizeOptions={[5, 10]}
                                                                sx={{ border: 0 }}
                                                                disableColumnFilter
                                                                disableColumnMenu
                                                                disableColumnSelector
                                                                disableDensitySelector
                                                                className="schemedata-table"
                                                                slots={{ toolbar: GridToolbar }}
                                                                slotProps={{
                                                                    toolbar: {
                                                                        showQuickFilter: true,
                                                                        printOptions: { disableToolbarButton: true },
                                                                        csvOptions: { disableToolbarButton: true },

                                                                    },
                                                                }}

                                                            />
                                                        </Paper>
                                                    </>
                                                )

                                                    : (<div className="addbank-ac text-center border py-3">
                                                        <div className="bankimage my-3  ">
                                                            <img src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                        </div>
                                                        <div className="addbank-info ">
                                                            <h5>{settings ? settings[0]?.digital_plan : ''}</h5>
                                                        </div>
                                                        <div className="addbank-btn text-white my-3">
                                                            <button className="text-white" onClick={() => { setshemaData(plandetails); document.getElementById('openPriceplanpopu').click() }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                        </div>
                                                    </div>)

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="activeboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#activeboxmsg" id="activepopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <h5 className="modal-title" id="exampleModalLongTitle">{settings ? `${settings[0]?.digital_plan}(cashback)` : ''}</h5>
                        </div>
                        <div className="modal-body  text-center">
                            <div className="popactive-img">
                                <img src="\assets\iocns\physical gold.png" />
                            </div>
                            <div className="pop-submsg mt-3">
                                <h4>The total gold is expected to be around 1 gram</h4>
                                <h4>Would you like to activate the plan</h4>
                            </div>
                            <div className="modal-footer border-0 justify-content-center ">
                                <button type="button " className="btn bg-success text-white" data-dismiss="modal" onClick={handleactive}>Active</button>
                                <button type="button " className="btn bg-danger text-white" data-dismiss="modal">Close</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div className="modal fade" id="cancelboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#cancelboxmsg" id="cancelpopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <h5 className="modal-title" id="exampleModalLongTitle">{settings ? settings[0]?.digital_plan : ''}</h5>
                        </div>
                        <div className="modal-body  text-center">
                            <div className="pop-cancel-icon">
                                <img src="\assets\iocns\cross mark.png" className="img-fluid" alt="" />
                            </div>
                            <div className="pop-submsg d-flex justify-content-center pt-2">

                                {
                                    activebank === 0 ? <h4 className="cancel-msg">
                                        To complete the cancellation process, kindly submit your bank details.
                                    </h4>
                                        : <h4 className="cancel-msg">If you cancel, the final amount will be {finalamount ? `₹${finalamount}` : ''} excluding VAT and service charges.</h4>
                                }

                            </div>
                            <div className="mt-3">

                                {
                                    activebank ? activebank === 1 ? (
                                        <>
                                            <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                            <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                                I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                            </label>
                                        </>
                                    ) : '' : ''
                                }


                                {/* {
                                    activebank === 1 ? (<>
                                        <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                        <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                            I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                        </label>
                                    </>)
                                        :  ''
                                } */}
                            </div>
                            <div className="modal-footer border-0 justify-content-center pop-can-btn">
                                {
                                    activebank === 0 ? <button type="button " className="btn bg-danger text-white" onClick={() => { document.getElementById('cancelpopmsg').click(); router('/user/mybank-account') }} >Submit</button> :
                                        <button type="button " className="btn bg-danger text-white" onClick={() => { PostCancel_id(ALlData_list[0]?.id) }} >Cancel</button>
                                }
                                <button type="button " className="btn bg-secondary text-white" data-dismiss="modal">Close</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Transaction;