import { createContext } from "react";

  const userdetail = {
       detail_info:'',
       setdetail_info :()=>{},
       token : '',
       settoken : ()=>{},
       kyc_detail :"",
       setkyc_detail  : ()=>{},
       emailname : '',
       setemailname : ()=>{},
  }

  


export const Logindetail = createContext(userdetail) 