import React, { useContext, useEffect } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useNavigate } from "react-router-dom";
import { Logindetail } from "../context/Logindetail.js";

const Myorder = ({settings}) => {

    const orderid = [
        {
            orderid: "00121",
            weight: "8.354 g",
            total: "4,800",
            purchasedate: "22-05-24",
            status: "pending"
        },
        {
            orderid: "00122",
            weight: "20.35 g",
            total: "8000",
            purchasedate: "21-05-24",
            status: "purchased"
        },
        {
            orderid: "00123",
            weight: "9.954 g",
            total: "58,093",
            purchasedate: "26-04-24",
            status: "Cancelled"
        },

        {
            orderid: "00124",
            weight: "48.54 g",
            total: "54,980",
            purchasedate: "11-03-24",
            status: "pending"
        },
    ]


    const navigate = useNavigate()

    const { token , detail_info } = useContext(Logindetail)

    const check = token

    // useEffect(()=>{
    //     //  if(!check ){
    //     //    navigate('/')
    //     //  }
    // },[])




    return (
        <>
            <HeadingBanner title={"My Orders"} />
            <section>
                <div className="container">
                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-8 col-lg-7">
                                <div className="siptrans-content">
                                    <div className="siptrans-head pb-3">
                                        <h4>My Orders</h4>
                                    </div>
                                    <div className="siptrans-table border pt-2 ">
                                        <div className="sipshow-search d-flex align-items-center  justify-content-between gap-sm-0 gap-3">
                                            <div className="sipshow d-flex align-items-center gap-sm-3 gap-2">
                                                <h4 className="m-0">Show</h4>
                                                <select className="form-select" id="inputGroupSelectshow">
                                                    <option value="1">10</option>
                                                    <option value="2">20</option>
                                                </select>
                                            </div>
                                            <div className="sip-search position-relative">
                                                <input type="text" className="form-control" id="inputemail" placeholder="Search" />
                                                <img src="\assets\iocns\Search.png" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="siptable-view">
                                            <table className="table">
                                                <thead>
                                                    <tr className="text-center">
                                                        <th scope="col">Order id</th>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Total</th>
                                                        <th scope="col">Purchase Date</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">View</th>
                                                        <th scope="col">Invoice</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orderid.map((r,i) => {
                                                            return (
                                                                <tr className="text-center" key={i}>
                                                                    <td>{r.orderid}</td>
                                                                    <td>{r.weight}</td>
                                                                    <td>{r.total}</td>
                                                                    <td>{r.purchasedate}</td>
                                                                    <td style={{ color: r.status == "pending" ? "orange" : r.status == "purchased" ? "green" : "red" }}>{r.status}</td>
                                                                    <td className="text-center"><img src="\assets\iocns\eye.png" className="img-fluid" /></td>
                                                                    <td className="text-center"><img src="\assets\iocns\invoice.png" className="img-fluid" /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Myorder;