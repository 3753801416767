import React, { useEffect, useState } from "react"
import { ArrowRight, Envelope } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from "formik"
import { Logindetail } from "../../context/Logindetail"
import { useContext } from "react"
import { Forgatpwd } from "./Forgatpwd"
import { Login_fun } from "../../api/Controllapi"
import { PagesApi } from "../../api/Pageapi"
import toast from "react-hot-toast"
import { Footerpages } from "../../context/Footerpages"
import { getprofile_fun } from "../../api/Dashboardapi"


function Login({ openSchemes }) {

  const [invalid, setinvalid] = useState()
  const router = useNavigate()
  const [spinner, setspinner] = useState(false)
  const { setdetail_info, settoken } = useContext(Logindetail)
  const initvalue = {
    'email': '',
    'password': ''
  }

  const signschema = Yup.object().shape({

    'email': Yup.string().email('Invalid Email')
      .required('Enter Your Email')
      .test(value => {
        return value === value.toLocaleLowerCase()
    }),

    'password': Yup.string()
      .required('Enter Your Password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character"
      ),
  })

  const { footerpage } = useContext(Footerpages)

  const Formrik = useFormik({
    initialValues: initvalue,
    validationSchema: signschema,
    onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
      setspinner(true)
      await Login_fun(value)
        .then((res) => {
          if (res.status === 403) {
            setErrors({ auth: "Authentication failed!" });
            setspinner(false)
          }
          else {
            localStorage.setItem("green_token", res.token)
            handlegetprofile()
            settoken(res.token);
            document.getElementById('closeloginmodal007')?.click();
            resetForm();
            setspinner(false);
            // if (openSchemes) {
            //   document.getElementById("openPriceplanpopu")?.click();
            // }
          }

        })
        .catch((err) => {
          setErrors({ auth: "Authentication failed!" });
          setspinner(false)

        })
    }
  })

  // const Agreepage = (slug) => {
  //   document.getElementById('closeloginmodal007')?.click();
  //   router(`/pages/${slug}`)
  // }


  const handlegetprofile = () => {
    getprofile_fun()
      .then((res) => {
        localStorage.setItem("green_login", JSON.stringify(res?.data));
        setdetail_info(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }



  return (
    <>

      <section>
        <div className="modal fade loginmodal" id="loginmodalview" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" >
            <div className="modal-content px-4 py-2">
              <div className="modal-header login-header justify-content-center border-0">
                <h3 className="modal-title">Welcome to Greenheap</h3>
                <button className="d-none" id="closeloginmodal007" data-bs-dismiss="modal" type="button"></button>
              </div>
              {/* {
                <div className="toast align-items-center text-bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
                  <div className="d-flex">
                    <div className="toast-body">
                      {invalid}
                    </div>
                  </div>
                </div>
              } */}
              <div className="modal-body login-body login-text">
                {Formrik?.errors?.auth && (
                  <div className="alert alert-danger w-100" role="alert">
                    <i className="fa fa-exclamation-triangle text-danger me-2" aria-hidden="true"></i>  {Formrik?.errors?.auth}
                  </div>
                )}
                <form onSubmit={Formrik.handleSubmit}>
                  <div className="login-email">
                    <label>Email</label>
                    <input type="email" className="form-control bg-light border" id="validationCustom01" {...Formrik.getFieldProps('email')} placeholder="Email" />
                    {
                      Formrik.touched.email && Formrik.errors.email ? (<span className="text-danger">{Formrik.errors.email}</span>) : null
                    }

                  </div>
                  <div className="login-password my-3">
                    <label>Password</label>
                    <input type="password" className="form-control bg-light border" id="validationCustom02" placeholder="Password"  {...Formrik.getFieldProps('password')} />
                    {
                      Formrik.touched.password && Formrik.errors.password ? (<span className="text-danger">{Formrik.errors.password}</span>) : null
                    }
                  </div>
                  <div className="login-terms my-2">
                    <div className="frgt-click d-flex justify-content-end">
                      <p style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#frgtpwd_modal">Forgot Password ?</p>
                    </div>
                    {/* {
                      footerpage ? (<h6>I agree to  <a href={`pages/${footerpage[0]?.slug}`} target="_blank"><span>Terms and Condition</span></a> and  <a href={`pages/${footerpage[1]?.slug}`} target="_blank"><span>Privacy Policy</span></a></h6>)
                        : (<h6>I agree to Terms and Condition and Privacy Policy </h6>)
                    } */}

                  </div>
                  <div className="login-footer text-center border-0  d-block">
                    <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Login'}</button>
                    <h4 className="pt-4">I don't have an account <span className="go-signup" data-bs-toggle="modal" data-bs-target="#signupmodalview" >Sign Up</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#signupmodalview" ><ArrowRight /></span></h4>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </section>

      <Forgatpwd />

    </>
  )
}
export default Login;