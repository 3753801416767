import React, { useContext, useEffect, useState, useCallback } from "react"
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import moment from "moment/moment"
import useRazorpay from "react-razorpay";
import toast from 'react-hot-toast';
import { Image, Shimmer } from 'react-shimmer'
// import { getInvoiceDown, getShemesSchedule, postMonthShec, SetPlanCancell } from "../api/Pageapi"
import { useNavigate } from "react-router-dom"
import { Logindetail } from "../context/Logindetail"
import { GetGoldPlantApi } from "../api/RouteScheme3.js";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { BookmarkCheckFill, BookmarkStarFill, BookmarkXFill, PauseCircleFill, SlashCircleFill } from "react-bootstrap-icons";

export default function GoldPlantsSchems({ settings }) {


    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const router = useNavigate()

    const { token, detail_info } = useContext(Logindetail)

    const [Shcudle, setShcudle] = useState([])
    const [full, setFull] = useState([])
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemData, setSchemaData] = useState()
    const [isLoading, setisLoading] = useState(true);
    // const [sort, setSort] = useState(5)
    // const [search, setSearch] = useState('');
    // const [transcation, setTranscation] = useState()
    // const [Entername, setEntername] = useState()
    const [ALlData_list, setALlData_list] = useState()
    const [spinner, setspinner] = useState(false)
    const [spinid, setspinid] = useState()
    const [plandetails, setplandetails] = useState()
    const [statuslist, setstatuslist] = useState({
        'active': '',
        'hold': '',
        'complete': '',
        'suspend': '',
        'cancel': ''
    })



    const handleGetSchema = (slug) => {
        setisLoading(true)
        GetGoldPlantApi(slug).then(res => {
            setShcudle(res?.data);
            setALlData_list(res?.data)
            setFull(res?.data)
            setplandetails(res?.plan)
            setstatuslist(previousState => {
                return { ...previousState, active: res?.active_count, hold: res?.hold_count, complete: res?.complete_count, suspend: res?.suspended_count, cancel: res?.cancel_count }
            })
            setisLoading(false)
        }).catch(err => {
            setisLoading(false)
        })
    }


    const handlespinner = (data) => {
        setspinid(data?.id)
        setspinner(true)
    }



    useEffect(() => {
        handleGetSchema(1);
    }, []);

    const handledownload = (value) => {
        const baseurl = process.env.REACT_APP_BASE_URL
        let fileName = `${moment(value?.created_at).format('DD-MM-YYYY')}-invoice.pdf`;
        const url = `${baseurl}gold_plant_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
                setspinid()
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
                setspinner(false)
                setspinid()
            });
    }


    // const handlesearch = () => {
    //     const name = Entername?.toLowerCase();
    //     const filtrer = ALlData_list.filter(e => e.beneficiary_id ? e.beneficiary?.name?.toLowerCase().includes(name) : detail_info?.name?.toLowerCase().includes(name));
    //     setShcudle(filtrer)
    // }

    const columns = [

        { field: 's_no', headerName: 'S.No', width: 115 },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,

        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150,
            valueGetter: (value, row) => `${row?.gold_gram ? `${row?.gold_gram}(gm)` : '-'}`,

        },
        {
            field: 'total',
            headerName: 'Total',
            width: 150,
            valueGetter: (value, row) => `${`₹${row?.amount}` ?? '-'}`,
        },
        {
            field: 'purchase_Date',
            headerName: 'Purchase Date',
            width: 150,
            valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY') ?? '-'}`,

        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (
                <button className="btn btn-secondary text-white">cancel</button>
            )
        },
        {
            field: 'invoice',
            headerName: 'Invoice',
            width: 100,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm" role="status"></span>) : (<img alt="" src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)
                    }
                </>
            ),
            sortable: false,
        }


    ];

    const paginationModel = { page: 0, pageSize: 5 };

    // useEffect(() => {
    //     if (Entername) {
    //         handlesearch();
    //     } else {
    //         setShcudle(ALlData_list);
    //     }

    // }, [Entername])




    return (
        <>
            <HeadingBanner title={isLoading ? 'Loading...' : settings ? settings[0]?.gold_plant : 'Scheme'} />
            <section>
                <div className="container">
                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-5"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-7">
                                <div className="siptrans-content">

                                    <div className="siptrans-head pb-3">
                                        {
                                            isLoading ? <h4><Shimmer className="rounded " width={180} height={30} /></h4> :
                                                <h4> {ALlData_list[0]?.gold_plant?.name ? ALlData_list[0]?.gold_plant?.name : plandetails?.name}</h4>
                                        }
                                    </div>
                                    {
                                        isLoading ? (
                                            <>
                                                <div className="row">
                                                    <div className="cox-xl-33">
                                                        <div className="">
                                                            <Shimmer width={175} height={100} className="rounded" />
                                                        </div>
                                                    </div>

                                                    <div className="cox-xl-33">
                                                        <div className="">
                                                            <Shimmer width={175} height={100} className="rounded" />
                                                        </div>
                                                    </div>

                                                    <div className="cox-xl-33">
                                                        <div className="">
                                                            <Shimmer width={175} height={100} className="rounded" />
                                                        </div>

                                                    </div>
                                                    <div className="cox-xl-33">
                                                        <div className="">
                                                            <Shimmer width={175} height={100} className="rounded" />
                                                        </div>
                                                    </div>
                                                    <div className="cox-xl-33">
                                                        <div className="">
                                                            <Shimmer width={175} height={100} className="rounded" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="siptrans-table border mt-4" >
                                                    <div className="siptable-view">
                                                        <table className="table">
                                                            <thead className="p-3">
                                                                <tr className="text-center">
                                                                    <th scope="col" >S.NO</th>
                                                                    <th scope="col" >Name</th>
                                                                    <th scope="col">Weight</th>
                                                                    <th scope="col">Total</th>
                                                                    <th scope="col">Purchase</th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Invoice</th>

                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Array.from({ length: 6 }).map((_, i) => {
                                                                        return (
                                                                            <tr className="text-center" key={i}>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block ms-2" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>
                                                                                <td className="text-center"><Shimmer height={25} width={100} className="rounded d-inline-block" /></td>



                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>

                                            </>
                                        )

                                            :

                                            statuslist?.active > 0 ? (
                                                <>
                                                    <div className="row">
                                                        <div className="cox-xl-33">
                                                            <div className="plan-viewcard comp status2" onClick={() => { handleGetSchema(1) }}>
                                                                <div className="plan-count">
                                                                    <h4>Actived</h4>
                                                                    <h2>{statuslist?.active}</h2>
                                                                </div>
                                                                <div className="icon-position">
                                                                    <BookmarkCheckFill />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cox-xl-33">
                                                            <div className="plan-viewcard comp status1" onClick={() => { handleGetSchema(3) }}>

                                                                <div className="plan-count ">
                                                                    <h4>Completed</h4>
                                                                    <h2>{statuslist?.complete}</h2>
                                                                </div>
                                                                <div className="icon-position">
                                                                    <BookmarkStarFill />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cox-xl-33">

                                                            <div className="plan-viewcard comp status3" onClick={() => { handleGetSchema(4) }}>
                                                                <div className="plan-count">
                                                                    <h4>Hold</h4>
                                                                    <h2>{statuslist?.hold}</h2>
                                                                </div>
                                                                <div className="icon-position">
                                                                    <PauseCircleFill />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="cox-xl-33">
                                                            <div className="plan-viewcard comp status4" onClick={() => { handleGetSchema(2) }}>
                                                                <div className="plan-count">
                                                                    <h4>Cancelled</h4>
                                                                    <h2>{statuslist?.cancel}</h2>
                                                                </div>
                                                                <div className="icon-position">
                                                                    <BookmarkXFill />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="cox-xl-33">
                                                            <div className="plan-viewcard comp status5" onClick={() => { handleGetSchema(5) }}>
                                                                <div className="plan-count">
                                                                    <h4>Suspended</h4>
                                                                    <h2>{statuslist?.suspend}</h2>
                                                                </div>
                                                                <div className="icon-position">
                                                                    <SlashCircleFill />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                        <DataGrid
                                                            rows={Shcudle.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                            columns={columns}
                                                            disableColumnFilter
                                                            disableColumnSelector
                                                            disableDensitySelector
                                                            disableColumnMenu
                                                            initialState={{ pagination: { paginationModel } }}
                                                            pageSizeOptions={[5, 10]}
                                                            sx={{ border: 0, marginTop: 3 }}
                                                            slots={{ toolbar: GridToolbar }}
                                                            slotProps={{
                                                                toolbar: {
                                                                    showQuickFilter: true,
                                                                    printOptions: { disableToolbarButton: true },
                                                                    csvOptions: { disableToolbarButton: true },

                                                                },
                                                            }}

                                                            className="schemedata-table"

                                                        />
                                                    </Paper>
                                                </>

                                            ) : (<div className="addbank-ac text-center border py-3">
                                                <div className="bankimage my-3  ">
                                                    <img alt="" src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                </div>
                                                <div className="addbank-info ">
                                                    <h5>Green Heap Gold Plant Scheme</h5>
                                                </div>
                                                <div className="addbank-btn text-white my-3">
                                                    <button className="text-white" onClick={() => { router('/scheme') }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                </div>
                                            </div>)
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
