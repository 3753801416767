"use client"

import React, { useEffect, useState } from "react"
import {getlive_fun } from "../api/Pageapi"

export const Liveprice = () => {

    const [live_price, setlive_price] = useState()
    
    const getliveprice =()=>{

        getlive_fun()
        .then((res)=>{
            setlive_price(res)
            
        })
        .catch((err)=>{
            console.log(err)
        })
    }

  
    useEffect(() => {
        getliveprice()
    },[])

    return (
           <div className="d-flex justify-content-center">
           {
                live_price ? (
                    <div className="goldsiver-box bg-white p-4 mt-5">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#goldtype" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Digital {live_price?.gold_price?.metal_type}</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#silvertype" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Digital {live_price?.silver_price?.metal_type}</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="goldtype" role="tabpanel">
                                <div className="livebuy d-flex align-items-center gap-2">
                                    <img src='\assets\iocns\live.png' className='img-fluid' />
                                    <h5 className='m-0'>Live Buy Price <span>{live_price?.gold_price?.percentage}</span></h5>
                                </div>
                                <div className="goldrate-gm d-flex  align-items-center justify-content-between my-3">
                                    <h3 className='m-0'>{live_price?.gold_price?.price_per_gram}/gm</h3>
                                    <h6 className='m-0'>+3% GST applicable</h6>
                                </div>
                            </div>
                            <div className="tab-pane fade show " id="silvertype" role="tabpanel">
                                <div className="livebuy d-flex align-items-center gap-2">
                                    <img src='\assets\iocns\live.png' className='img-fluid' />
                                    <h5 className='m-0'>Live Buy Price <span>{live_price?.silver_price?.percentage}</span></h5>
                                </div>
                                <div className="goldrate-gm d-flex  align-items-center justify-content-between my-3">
                                    <h3 className='m-0'>{live_price?.silver_price?.price_per_gram}/gm</h3>
                                    <h6 className='m-0'>+3% GST applicable</h6>
                                </div>
                            </div>
                        </div>

                    </div>

                ) : ""
            }
           </div>

    )

}

