import instance from "../Config/Config";


export const DIGITAL_SCHEME_PLAN = "get_digital_plan"
export const DIGITAL_PLAN_PURCHASE = "digital_plan_purchase"
export const DIGITAL_TRANSACTION = "get_digital_transaction"
export const PLAN_ACTIVE = "plan_active"
export const DAILY_PAY_SCHEME = "pay_digital_plan"
export const CANCEL_SCHEME_URL = "digital_plan_cancel"
export const PLAN_BENEFIT_URL = "digital_plan_benifits"


export const Digitalscheme_fun = async()=>{
       const response = await instance.get(`${DIGITAL_SCHEME_PLAN}`)
       return response?.data
}

export const Savedigitalplan = async(value)=>{
       const response = await instance.post(`${DIGITAL_PLAN_PURCHASE}`,value)
       return response?.data
}

export const Getdigital_trans = async(slug)=>{
       const response = await instance.get(`${DIGITAL_TRANSACTION}?plan_status=${slug}`)
       return response?.data
}

export const Post_PlanActive = async(id)=>{
       const body = {
              "purchase_id " : id
       }
       const response = await instance.post(`${PLAN_ACTIVE}`,body)
       return response?.data
}

export const PayDaily_Scheme = async(value)=>{
       const response = await instance.post(`${DAILY_PAY_SCHEME}`,value)
       return response?.data
}

export const Cancel_scheme = async(id)=>{
       const response = await instance.post(`${CANCEL_SCHEME_URL}?purchase_id=${id}`)
       return response?.data
}

export const GetPlanbenefit = async()=>{
       const response = await instance.get(`${PLAN_BENEFIT_URL}`)
       return response?.data
}