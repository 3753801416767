import React, { useEffect } from "react"
import HeadingBanner from "../Components/HeadingBanner"
import FAQ from "../Components/FAQ"

export  const Faqpage = ({setting_datas})=>{

      useEffect(()=>{
        window.scrollTo(0,0)

      },[])

    return(
      <>
          <HeadingBanner title={"FAQ"} />

          <FAQ setting_data={setting_datas}/>
        
      </>
    )

}