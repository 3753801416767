
import { useFormik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import * as Yup from 'yup'
import { resetpwd_fun } from "../../api/Controllapi"
import toast from "react-hot-toast"
import { Logindetail } from "../../context/Logindetail"

export const Resetpwd = () => {



    const { emailname } = useContext(Logindetail)


    const [spinner, setspinner] = useState(false)

    const initialValues = {
        "email": "",
        "otp": "",
        "password": "",
        "password_confirmation": ""
    }

    const validationSchema = Yup.object().shape({
        "email": Yup.string().email('Invalid email')
            .required('Enter Your Email'),
        "otp": Yup.string().required('Enter your OTP'),
        "password": Yup.string().required("Enter your password ")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
               "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character"
            ),

        "password_confirmation": Yup.string().required("Enter Confirm Password")
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    })


    const Formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (value, { resetForm, setErrors }) => {
            setspinner(true)
            console.log(value)
            resetpwd_fun(value)
                .then((res) => {
                    document.getElementById('resetpwd_closemodal')?.click()
                    toast.success(res?.message)
                    setspinner(false)
                    resetForm()
                })
                .catch(err => {
                    console.log(err)
                    setspinner(false)
                    if(!err){
                        resetForm()
                    }
                    else{
                        toast.error(err?.response?.data?.message)
                    }
                })
        }
    })

    useEffect(() => {
        Formik.setFieldValue('email', emailname)
    }, [emailname || !emailname])

    useEffect(()=>{
        const value = Formik.getFieldProps('otp').value;
        if(value){
            Formik.setFieldValue('otp',null)
        }
    },[])



    return (
        <>
            <div className="modal fade" id="resetmodal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered modal-lg reset-model" role="document">
                    <div className="modal-content px-4 py-2">
                        <div className="modal-header login-header forgotmodal  resetmodalbox text-center border-0" style={{ display: "block" }}>
                            <h3 className="modal-title" id="exampleModalLabel">Reset Password</h3>
                            <button type="button" className="d-none" id="resetpwd_closemodal" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body login-text">
                            <form onSubmit={Formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 py-2">
                                        <label>Email</label>
                                        <input name="email" placeholder="Email" readOnly={true} className="form-control border" {...Formik.getFieldProps("email")} />
                                        {
                                            Formik.touched.email || Formik.errors.email ? (<span className="text-danger">{Formik.errors.email}</span>) : ""
                                        }
                                    </div>
                                    <div className="col-lg-6 py-2">
                                        <label htmlFor="">Otp</label>
                                        <input name="text" placeholder="OTP" className="form-control border" {...Formik.getFieldProps("otp")} id="otp" />
                                        {
                                            Formik.touched.otp || Formik.errors.otp ? (<span className="text-danger">{Formik.errors.otp}</span>) : ""
                                        }
                                    </div>
                                    <div className="col-lg-6 py-2 ">
                                        <label htmlFor="">Password</label>
                                        <input name="password" placeholder="Password" className="form-control border" {...Formik.getFieldProps("password")} />
                                        {
                                            Formik.touched.password || Formik.errors.password ? (<span className="text-danger">{Formik.errors.password}</span>) : ""
                                        }
                                    </div>
                                    <div className="col-lg-6 py-2">
                                        <label htmlFor="">Confirm Password</label>
                                        <input name="password" placeholder="Password confirmation" className="form-control border" {...Formik.getFieldProps("password_confirmation")} />
                                        {
                                            Formik.touched.password_confirmation || Formik.errors.password_confirmation ? (<span className="text-danger">{Formik.errors.password_confirmation}</span>) : ""
                                        }
                                    </div>
                                    <div className="col-lg-12 text-center my-3">
                                        <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}