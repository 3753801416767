import React, { useEffect, useState } from 'react'
import 'rc-table/assets/index.css';
import Table from 'rc-table';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { similaprod_fun, singleproduct } from '../../api/Pageapi';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Grid } from "swiper/modules";
import { Shimmer } from 'react-shimmer';
import { object } from 'yup';

export default function ProductDetails() {

  const [response, setresponse] = useState()
  const [IsLoading, setIsLoading] = useState(true)
  const [similar_data, setsimilar_data] = useState([])
  const [imageid, setimageid] = useState()

  const router = useNavigate();

  const product_id = useParams();

  const { state } = useLocation();

  const getsingle_product = async () => {

    singleproduct(product_id?.id)
      .then((res) => {
        setresponse(res?.data)
        setIsLoading(false)
        setimageid(res?.data?.images[0]?.id)
      })

      .catch((err) => {
        console.log(err)
        setIsLoading(true)
      })

  }

  useEffect(() => {
    if (product_id || state) {
      getsingle_product()
    } else {
      router(-1)
    }
  }, [product_id, state])


  const getsimialr_product = async () => {

    similaprod_fun(product_id?.id, response?.category_id)
      .then((res) => {
        setsimilar_data(res?.data)
        setIsLoading(false)

      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getsimialr_product()
  }, [response])


  const columns = [
    {
      title: 'Component',
      dataIndex: 'component',
      key: 'Component',
    },
    {
      title: 'Gold Rate(18KT)',
      dataIndex: 'gold_rate',
      key: 'gold_rate',
    },
    {
      title: 'Weight (g)',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Final Value',
      dataIndex: 'final_value',
      key: 'final_value',
    },
  ]


  const TableData = [
    {
      "Component": '18KT Plain Gold',
      "Gold Rate(18KT)": "Rs 5650.36/g",
      "Weight (g)": "4.322g",
      "Discount": '-',
      "Final Value": 'Rs 24420.86'
    },
    {
      "Component": 'Stone Detail',
      "Gold Rate(18KT)": "-",
      "Weight (g)": "5.595 ct/ 1.119 g",
      "Discount": '-',
      "Final Value": 'Rs 54425.1'
    },
    {
      "Component": 'Making Charges',
      "Gold Rate(18KT)": "-",
      "Weight (g)": "-",
      "Discount": '-',
      "Final Value": 'Rs 18899.00'
    },
    {
      "Component": 'Sub Total',
      "Gold Rate(18KT)": "",
      "Weight (g)": "",
      "Discount": '',
      "Final Value": 'Rs 97744.96'
    },
    {
      "Component": 'GST',
      "Gold Rate(18KT)": "",
      "Weight (g)": "",
      "Discount": '',
      "Final Value": 'Rs 2932.35'
    }
  ]


  return (

    <>
      <section className='breadcamp'>
        <div className='container'>
          <div className='d-flex gap-2'>
            <h4 className='mb-0'></h4>
          </div>
        </div>
      </section>



      <section className='pro_de'>
        <div className='container'>

          <div className='row mb-3'>
            <div className='col-lg-6 col-md-5 col-sm-12 mb-3'>

              {
                IsLoading ? (

                  <>
                    <div className="tab-content d-flex  justify-content-center pt-3" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="pills-img4" role="tabpanel" aria-labelledby="pills-img4" tabindex="0" ><Shimmer className='rounded ' height={400} width={560} /></div>
                    </div>
                    <ul className="nav nav-pills mb-3 gold-slide justify-content-center mt-4" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link"><Shimmer className='rounded' width={60} height={60} /></button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link"><Shimmer className='rounded' width={60} height={60} /></button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link"><Shimmer className='rounded' width={60} height={60} /></button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link"><Shimmer className='rounded' width={60} height={60} /></button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link"><Shimmer className='rounded' width={60} height={60} /></button>
                      </li>
                    </ul>
                  </>)

                  : (

                    response?.images.length > 0 ? (<div className='detail-imges'>
                      <div className="tab-content border rounded " id="pills-tabContent">
                        {
                          response?.images.map((data) => {
                            return (
                              <div className={imageid == data?.id ? "tab-pane fade show active  text-align-center  d-flex justify-content-center" : "tab-pane fade  text-align-center"} id={`${data?.id}`} role="tabpanel" aria-labelledby="pills-img1" tabindex="0"><img src={data?.image} className='img-fluid' /> </div>
                            )
                          })
                        }
                      </div>

                      <Swiper
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode={true}
                        breakpoints={{
                          320: {
                            slidesPerView: 1
                          },
                          576: {
                            slidesPerView: 2
                          },
                          992: {
                            slidesPerView: 3
                          },
                          1200: {
                            slidesPerView: 5
                          }
                        }}
                        modules={[FreeMode, Pagination]}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                        className="main_slider33"
                      >

                        {
                          response?.images.map((data, i) => {
                            return (
                              <SwiperSlide>
                                <button  className = {imageid == data?.id ? 'nav-link  active rounded btn-bor ' : 'nav-link  active rounded '} id="pills-home-tab" data-bs-toggle="pill" data-bs-target={`#${data?.id}`} type="button" role="tab" aria-controls="pills-home" aria-selected="true"  onClick={() => { setimageid(data?.id) }}><img src={data?.image} className='img-fluid ' /></button>
                              </SwiperSlide>

                            )
                          })
                        }
                      </Swiper>


                    </div>) : ''



                  )
              }
            </div>
            <div className='col-lg-6 col-md-7  col-sm-12 mb-3'>

              {
                IsLoading ? (

                  <div className='prdd mt-sm-3 p-3 mb-lg-2 '>
                    <h2 className='mb-4 '> <Shimmer width={330} height={70} className='rounded'></Shimmer></h2>
                    <div className='row mb-3'>
                      <div className='col-sm-6 col-4'>
                        <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                      <div className='col-sm-6 col-8'>
                      <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-sm-6 col-4'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                      <div className='col-sm-6 col-8'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-sm-6 col-4'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                      <div className='col-sm-6 col-8'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-sm-6 col-4'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                      <div className='col-sm-6 col-8'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-sm-6 col-4'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                      <div className='col-sm-6 col-8'>
                          <h5><Shimmer width={280} height={30} className='rounded' /></h5>
                      </div>
                    </div>
                  </div>

                ) : (

                  <div className='prdd mt-sm-3'>
                    {/* <h2 className='mb-4'>{response.unit_name ?? ''} {response.name ?? ''}</h2> */}
                    <h2>{response?.name ? response?.name : ''}</h2>
                    <div className='d-flex justify-content-xl-between  justify-content-md-evenly   align-items-center mb-4'>
                      <div className='d-flex gap-2 align-items-end'>
                        <h5 className='text-secondary'>Price</h5>
                        <h2 className='text-color2'>₹ {response?.price ? response?.price : ''}</h2>
                      </div>
                      {/* <div>
                      <button type='button' className='btn'>Buy Now</button>
                      </div> */}
                    </div>

                    {
                      response?.material_name ? (

                        <div className='row mb-3'>
                          <div className='col-sm-6 col-4'>
                            <h5>Material</h5>
                          </div>
                          <div className='col-sm-6 col-8'>
                            <h5><span className='me-3'>:</span>{response.material_name}</h5>
                          </div>
                        </div>
                      ) : ''
                    }

                    {
                      response?.gold_purity ? (
                        <div className='row mb-3'>
                          <div className='col-sm-6 col-4'>
                            <h5>Gold Purity</h5>
                          </div>
                          <div className='col-sm-6 col-8'>
                            <h5><span className='me-3'>:</span>{response.gold_purity}</h5>
                          </div>
                        </div>
                      ) : ''
                    }

{/* 
                    {
                      response?.unit_name ? (

                        <div className='row mb-3'>
                          <div className='col-sm-6 col-4'>
                            <h5>Weight</h5>
                          </div>
                          <div className='col-sm-6 col-8'>
                            <h5><span className='me-3'>:</span>{response?.unit_name ?? ''}</h5>
                          </div>
                        </div>

                      ) : ""
                    } */}


                    {
                      response?.quality ? (
                        <div className='row mb-3'>
                          <div className='col-sm-6 col-4'>
                            <h5>Quality</h5>
                          </div>
                          <div className='col-sm-6 col-8'>
                            <h5><span className='me-3'>:</span>{response?.quality}</h5>
                          </div>
                        </div>
                      ) : ''
                    }
                  </div>)
              }



              <div className='rerfs d-flex justify-content-center text-center align-items-center mb-3'>
                <div className='d-flex flex-column gap-2'>
                  <div className=''>
                    <img src='/assets/iocns/pure gold.png' />
                  </div>
                  <h5 className='lh-base'>24K Pure Gold Quality Guaranteed</h5>
                </div>

                <div className='d-flex flex-column gap-2'>
                  <div className=' '>
                    <img src='/assets/iocns/secure delivery.png' />
                  </div>
                  <h5 className='lh-base'>100% Secure with Delivery</h5>
                </div>

                <div className='d-flex flex-column gap-2'>
                  <div className=''>
                    <img src='/assets/iocns/best price.png' />
                  </div>
                  <h5 className='lh-base'>Best Prices in the market</h5>
                </div>

              </div>

              {/* <div className='eeffe d-flex justify-content-between flex-wrap align-items-center'>
                <div className='d-flex  align-items-center '>
                  <img src='/assets/iocns/Location.png' />
                  <input type="text" className='form-control' placeholder='Enter Your pin code' />
                </div>
                <div>
                  <p className='mb-0 text-color mt-sm-0 mt-2'>Check Expected Delivery</p>
                </div>
              </div> */}

            </div>
          </div>

          {/* <div className='prod-desc' >
            <h2 className='fw-bold'>Description</h2>
            <div className='d-flex gap-3  align-items-start'>
              <div className='dots mt-2'></div>
              <h4 className='1bh-base fw-normal'>Greenheap coins are set in 24 Karat Yellow Gold</h4>
            </div>
            <div className='d-flex gap-3 align-items-start'>
              <div className='dots mt-2'></div>
              <h4 className='1bh-base fw-normal'>Guaranteed weight and purity: Specially manufactured in state of the art facilities, with zero negative tolerance for weight and purity</h4>
            </div>
            <div className='d-flex gap-3 align-items-start'>
              <div className='dots mt-2'></div>
              <h4 className='1bh-base fw-normal'>Sealed in international quality packaging with a unique serial number, ensures foolproof quality and complete traceability for each coin</h4>
            </div>
          </div> */}

          {
            IsLoading ? (
              <div className='prod-desc productdetail-shimmer' >
                <h2 className='fw-bold'>Description</h2>
                <h4 className='1bh-base fw-normal'><Shimmer height={250} width={860} className='rounded' /></h4>
              </div>
            ) : (
              <div className='prod-desc' >
                <h2 className='fw-bold'>Description</h2>
                <h4 className='1bh-base fw-normal prod-listview' dangerouslySetInnerHTML={{ __html: response?.description }}></h4>
              </div>
            )
          }

        </div>
      </section>

      <section className='price-break'>
        <div className='container'>
          <h2 className='fw-bold mb-3'>Price Breakup</h2>

          <div>
            <table className="table  d-none  table-borderless">
              <thead>
                <tr>
                  <th scope="col">Component</th>
                  <th scope="col">Gold Rate(18KT)</th>
                  <th scope="col">Weight (g)</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Final Value</th>

                </tr>
              </thead>
              <tbody className="table-group-divider">
                <tr>
                  <td>18KT Plain Gold</td>
                  <td>Rs 5650.36/g</td>
                  <td>4.322g</td>
                  <td>-</td>
                  <td>Rs 24420.86</td>
                </tr>
                <tr>
                  <td>Stone Detail</td>
                  <td>-</td>
                  <td>5.595 ct/ 1.119 g</td>
                  <td>-</td>
                  <td>Rs 54425.1</td>
                </tr>
                <tr>
                  <td >Making Charges</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>Rs 18899.00</td>
                </tr>

                <tr>
                  <td >Sub Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Rs 97744.96</td>
                </tr>

                <tr>
                  <td >GST</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Rs 2932.35</td>
                </tr> *

                {/* {
                    response?.gold_rates.map((r) => {
                      return (
                        <tr>
                          <td>{r.component}</td>
                          <td>{r.gold_rate}</td>
                          <td>{r.weight}</td>
                          <td>{r.discount}</td>
                          <td>{r.final_value}</td>
                        </tr>
                      )
                    })
                  } */}

              </tbody>
            </table>
            {
              IsLoading ? (<div className="siptable-view">
                <table className="table border rounded">
                  <thead>
                    <tr>
                      <th scope="col" className='h5'>Component</th>
                      <th scope="col"  className='h5'>Gold Rate(18KT)</th>
                      <th scope="col"  className='h5'>Weight</th>
                      <th scope="col"  className='h5'>Discount</th>
                      <th scope="col"  className='h5'>Final Value</th>
                    </tr>
                  </thead>

                  <tbody className=" border rounded" >
                    {
                      Array.from({ length: 5 }).map((_, i) => {
                        return (
                          <tr className="text-center" key={i}>
                            <td className="text-center"><Shimmer height={15} width={100} className="rounded ms-2" /></td>
                            <td className="text-center"><Shimmer height={15} width={100} className="rounded" /></td>
                            <td className="text-center"><Shimmer height={15} width={100} className="rounded" /></td>
                            <td className="text-center"><Shimmer height={15} width={150} className="rounded" /></td>
                            <td className="text-center"><Shimmer height={15} width={150} className="rounded" /></td>
                            
                     
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>) :

                response?.gold_rates?.length > 0 ?
                  <Table scroll={{ x: 650 }} columns={columns} data={response?.gold_rates} aria-labelledby="lblPeopleTable" /> : ''
            }

          </div>
        </div>
      </section>



      {
        IsLoading ? (<>

          <div className="container">
            <h2 className='fw-bold mb-4'>Similar Products you may like</h2>
            <div className="row">
              {
                Array.from({ length: 4 }).map((_, i) => {
                  return (
                    <div className='col-xxl-2 col-xl-3  col-lg-3 col-md-4 mb-4 col-sm-6' key={i}>
                      <div className='simlar_pr  rounded-3 px-3'>
                        <div className='mb-3 d-flex justify-content-center '>
                          <Shimmer width={230} height={150} className='rounded' />
                        </div>
                        <div >
                          <p className='d-flex  justiy-content-center'  > <Shimmer width={150} height={20} className='rounded' /></p>
                          <p className='d-flex  justiy-content-center' >  <Shimmer width={900} height={20} className='rounded' /></p>
                          <p className='d-flex  justiy-content-center' >  <Shimmer width={40} height={20} className='rounded' /></p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

        </>) : (
          <section className='price-break'>
            <div className='container sim'>
              <h2 className='fw-bold mb-4'>Similar Products you may like</h2>

              <Swiper
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                breakpoints={{
                  320: {
                    slidesPerView: 1
                  },
                  576: {
                    slidesPerView: 2
                  },
                  992: {
                    slidesPerView: 3
                  },
                  1200: {
                    slidesPerView: 5
                  }
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                className="main_slider"
              >

                {
                  similar_data ? similar_data?.map((res, i) => {
                    return (
                      <SwiperSlide>
                        <div className='simlar_pr border rounded-3' key={i}>
                          <div className='mb-3 d-flex justify-content-center '>
                            <img src={res.image} className='img-fluid' />
                          </div>
                          <div className='gap-2 d-flex flex-column text-center'>
                            <p className='one_line'> {res.name}</p>
                            <p>{res.price}</p>
                            <div>
                              <button type='button' className='btn' >View Details</button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                    )
                  }
                ) : ''
                }
              </Swiper>
            </div>
          </section>
        )
      }

    </>



  )
}
