import instance from "../Config/Config"


export const login_url = "login"
export const signup_url = "signup"
export const frgtpwd = "forgotPassword"
export const resetpwd = "resetPassword"
export const gender_url = "genders"
export const Testimonal = "testimonials"
export const setting = "setting"
export const role_url = "role"
export const COUNTRY_URL = "get_country"
export const STATE_URL = "get_state"
export const CITY_URL = "get_city"
export const PLANS = "plans"
export const REFERENCE ="reference"

export const Login_fun = async (value) => {
    const response = await instance.post(`${login_url}`, value)
    return response.data
}

export const Signup_fun = async (value) => {
    const response = await instance.post(`${signup_url}`, value);
    return response.data;
}

export const verify_pwd = async(value)=>{
    const response = await instance.post(`${frgtpwd}`, value)
    return response.data
}

export const trestiAPi = async()=>{
    const response = await instance.get(`${Testimonal}`)
    return response?.data
}

export const reference_fun = async()=>{
    const response = await instance.get(`${REFERENCE}`)
    return response?.data
}



export const resetpwd_fun = async(value)=>{
    const response = await instance.post(`${resetpwd}`, value)
    return response.data
}



export const GenderApi = async()=>{
    const response = await instance.get(`${gender_url}`)
    return response.data
}

export const getSettingApi = async()=>{
    const response = await instance.get(`${setting}`)
    return response?.data
}

export const Country_fun = async()=>{
    const response = await instance.get(`${COUNTRY_URL}`)
    return response?.data
}


export const State_fun = async(id)=>{
    const response = await instance.get(`${STATE_URL}?country_id=${id}`)
    return response?.data
}

export const City_fun = async(country_id , state_id)=>{
    const response = await instance.get(`${CITY_URL}?country_id=${country_id}&state_id=${state_id}`)
    return response?.data
}
export const  schemeplans = async(id)=>{
    const response = await instance.get(`${PLANS}?id=${id}`)
    return response?.data
}