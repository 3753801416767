import React, { useContext, useEffect, useState } from 'react'
import HeadingBanner from '../Components/HeadingBanner.jsx'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { contactsave } from '../api/Pageapi.js'
import toast from 'react-hot-toast';
import AOS from 'aos';

export default function ContactUs({ setting_data }) {

  const [spinner, setspinner] = useState(false)



  const initvalue = {
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_no": "",
    "requirement": ""
  }
  const updateschema = Yup.object().shape({
    "first_name": Yup.string().required('Enter Your First Name')
      .matches(/^([^0-9]*)$/, "Invalid First name")
    ,
    "last_name": Yup.string().required('Enter Your Last Name')
      .matches(/^[a-zA-Z ]*$/, "Invalid Last name"),
    "email": Yup.string()
      .email('InValid Email')
      .test(value => {
        return value === value.toLocaleLowerCase()
      })
      .required('Enter Your Email'),
    "phone_no": Yup.string()
      .min(10, 'Min 10 digits')
      .max(10, 'Max 10 digits')
      .required("Enter Your Phone Number")
      .matches(/^[0-9]+$/, "Invalid  Phone Number"),

  })

  const Formik = useFormik({
    initialValues: initvalue,
    validationSchema: updateschema,

    onSubmit: async (value, { setErrors, resetForm }) => {
      setspinner(true)
      await contactsave(value)
        .then((res) => {
          toast.success(res?.message)
          resetForm()
          setspinner(false)
        })
        .catch((err) => {
          if (err?.response?.status == "400") {
            toast.error(err?.response?.data?.email)
          }
          setspinner(false)


        })

    }
  })

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);

  return (
    <>
      <HeadingBanner title={"Contact Us"} />

      <section>
        <div className='container '>
          <div className='row'>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="con-location text-center" data-aos="fade-down">
                <div className='d-flex justify-content-center'>
                  <div className="con-loc-icon text-center">
                    <img src='/assets/iocns/Location 1.png' alt='' />
                  </div>
                </div>
                <div className="con-office my-4">
                  <h4>Office Address</h4>
                  <p>{setting_data[0]?.address1 + ' ' + setting_data[0]?.address2}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="con-location border-loc text-center " data-aos="fade-down">
                <div className="d-flex justify-content-center">
                  <div className="con-loc-icon text-center">
                    <img src="\assets\iocns\call 2.png" alt='' />
                  </div>
                </div>
                <div className="con-office my-4">
                  <h4>Contact Us</h4>
                  <p className='m-0'>{setting_data[0]?.phone ?? ''},</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="con-location text-center" data-aos="fade-down">
                <div className='d-flex justify-content-center'>
                  <div className="con-loc-icon text-center">
                    <img src='\assets\iocns\Follow.png' alt='' />
                  </div>
                </div>
                <div className="con-office my-xl-4 my-lg-3 my-md-3 py-3 py-sm-0">
                  <h4>Follow Us</h4>
                </div>
                <div className="follow-icon ">
                  <a href={setting_data[0]?.facebook} target='_blank' rel="noreferrer">
                    <div className='social-bg'>
                      <img alt='' src='/assets/iocns/Facebook_1.png' />
                    </div>
                  </a>
                  <a href={setting_data[0]?.instagram} target='_blank' rel="noreferrer">
                    <div className='social-bg'>
                      <img alt='' src='/assets/iocns/insta 1.png' />
                    </div>
                  </a>
                  <a href={setting_data[0]?.twitter} target='_blank' rel="noreferrer">
                    <div className='social-bg'>
                      <img alt='' src='/assets/iocns/X_1.png' />
                    </div>
                  </a>
                  <a href={setting_data[0]?.youtube} target='_blank' rel="noreferrer">
                    <div className='social-bg'>
                      <img alt='' src='/assets/iocns/Youtube 1.png' />
                    </div>
                  </a>
                  <a href={setting_data[0]?.linkedin} target='_blank' rel="noreferrer">
                    <div className='social-bg'>
                      <img alt='' src='/assets/iocns/Linkedin 1.png' />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section>
        <div className="contact-form-img position-relative " >
          <div className="contact-image">
            <img src='\assets\images\contact us.png' className='img-fluid' alt='' />
          </div>
          <div className="contact-form " data-aos="fade-right">
            <div className="container">
              <form onSubmit={Formik.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6 col-sm-6 col-6 login-text ">
                    <label>First Name</label>
                    <input type="text" className="form-control" id="inputfirtname" placeholder="First Name" {...Formik.getFieldProps("first_name")} />
                    {
                      Formik.touched.first_name && Formik.errors.first_name ? (<p className="text-danger">{Formik.errors.first_name}</p>) : null
                    }
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-6  login-text">
                    <label >Second Name</label>
                    <input type="text" className="form-control" id="inputsecondname" placeholder="Second Name" {...Formik.getFieldProps("last_name")} />
                    {
                      Formik.touched.last_name && Formik.errors.last_name ? (<p className="text-danger">{Formik.errors.last_name}</p>) : null
                    }
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-6 login-text ">
                    <label >Email</label>
                    <input type="Email" className="form-control" id="inputemail" placeholder="Email" {...Formik.getFieldProps("email")} />
                    {
                      Formik.touched.email && Formik.errors.email ? (<p className="text-danger">{Formik.errors.email}</p>) : null
                    }
                  </div>
                  <div className="form-group col-md-6 col-sm-6  col-6 login-text">
                    <label >Phone</label>
                    <input type="text" className="form-control" id="inputPhone" placeholder="Phone" {...Formik.getFieldProps("phone_no")} />
                    {
                      Formik.touched.phone_no && Formik.errors.phone_no ? (<p className="text-danger">{Formik.errors.phone_no}</p>) : null
                    }
                  </div>
                </div>
                <div className="form-group submit login-text">
                  <label>Requirement (Optional)</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows={5} placeholder='Type message' {...Formik.getFieldProps("requirement")}></textarea >
                </div>
                <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
              </form>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="contact_map" data-aos="fade-up">
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCOYU6x7yqbUnNRtBuygEfCX9NgWakZRLw&q=${setting_data[0]?.address1 + ',' + setting_data[0]?.address2}`}
              title='googlemap'></iframe>
          </div>
        </div>
      </section>




    </>
  )
}
