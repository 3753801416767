import React, { useContext, useEffect, useState, useCallback } from "react"
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import moment from "moment/moment"
import useRazorpay from "react-razorpay";
import toast from 'react-hot-toast';
import { Image, Shimmer } from 'react-shimmer'
import { getInvoiceDown, getShemesSchedule, postMonthShec, SetPlanCancell } from "../api/Pageapi"
import { useNavigate } from "react-router-dom"
import { Logindetail } from "../context/Logindetail"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { render } from "@testing-library/react";
import { SchemeDetails } from "../context/SchemeDetails.js";
import { BookmarkCheckFill, BookmarkStar, BookmarkStarFill, BookmarkX, BookmarkXFill, Check2Circle, HourglassTop, PauseCircle, PauseCircleFill, SlashCircle, SlashCircleFill } from "react-bootstrap-icons";

export default function ChitSchemes({ settings }) {


    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const router = useNavigate()

    const { token, detail_info } = useContext(Logindetail)

    const [Shcudle, setShcudle] = useState([])
    // const [full, setFull] = useState([])
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [transcation, setTranscation] = useState()
    const [ALlData_list, setALlData_list] = useState()
    const [DataPage, setDataPage] = useState(0)
    // const [Totalpage, setTotalpage] = useState()
    const [spinner, setspinner] = useState(false)
    const [spinid, setspinid] = useState()
    const [checked, setchecked] = useState()
    const [cancel_slug, setcancel_slug] = useState()
    const [plandetails ,setplandetails] =useState()
    const [statuslist, setstatuslist] = useState({
        'active': '',
        'hold': '',
        'complete': '',
        'suspend': '',
        'cancel': ''
    })



    const currentDate = new Date();

    const handleGetSchema = (slug) => {
        setisLoading(true)
        getShemesSchedule(slug).then(res => {
            setShcudle(res?.data);
            setALlData_list(res?.data)
            setplandetails(res?.plan)
            // setFull(res?.data)
            // setTotalpage(res?.total)
            setcancel_slug(res?.cancel_slug)
            setisLoading(false)
            setstatuslist(previousState => {
                return { ...previousState, active: res?.active_count, hold: res?.hold_count, complete: res?.complete_count, suspend: res?.suspended_count, cancel: res?.cancel_count }
            })
        }).catch(err => {
            setisLoading(false)
        })
    }


    const handlePayment = useCallback(async (data) => {
        const options = {
            key: RAZAR_KEY,
            amount: (data?.amount) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "amount": data?.amount,
                    "purchase_id": data?.id,
                    "transaction_id": res?.razorpay_payment_id,
                }
                postMonthShec(body, token).then(res => {
                    // navigate('/dashboard');
                    handleGetSchema();
                    toast.success("Payment success!")
                    setShowRazorpayModal(false);
                    setTranscation([])
                }).catch(err => {
                    console.log('err', err.message)
                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, []);


    const handlepayModal = (data) => {
        handlePayment(data);
    }


    useEffect(() => {
        handleGetSchema(1);

    }, [DataPage]);


    const handledownload = (value) => {

        const baseurl = process.env.REACT_APP_BASE_URL

        let fileName = `${value?.paid_date}-invoice.pdf`;
        const url = `${baseurl}generate_invoice?id=${value?.id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
            });
    }



    // const initialDate = new Date('2024-3-10'); // Month is 0-indexed, so September is 8

    // // Add three months to the initial date
    // const threeMonthsLater = new Date(initialDate);
    // threeMonthsLater.setMonth(initialDate.getMonth() + 3);

    // const lkndlskd = moment('18-10-2024', "DD-MM-YYYY").add(3, 'M').month() + 1

    // const jksbfksj = (new Date().getMonth() + 1);

    // // Get the current date
    // const currentDate1 = new Date('2024-10-19')

    // // Check if three months later is before the current date
    // const value = threeMonthsLater < currentDate;

    // console.log('llllll', lkndlskd, 'iiiii', jksbfksj, 'ooooo');


    function addMonths(date, months) {
        const result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    }




    const setcancell = (id) => {

        //   if(!checked){
        //     toast.error('Please Agree Terms & condition')

        //   }
        //   else{
        //     document.getElementById('cancelpopmsg').click()
        //     SetPlanCancell(id, token)
        //     .then((res) => {
        //         console.log(res)
        //         toast.success(res?.data)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        //   }
        handlecancel(id)

    }


    const handlecancel = (id) => {

        if (!checked) {
            toast.error('Please Agree Terms & condition')

        }
        else {
            document.getElementById('cancelpopmsg').click()
            SetPlanCancell(id, token)
                .then((res) => {
                    console.log(res)
                    toast.success(res?.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }


    // const handlesearch = () => {
    //     const name = Entername?.toLowerCase();
    //     const filtrer = ALlData_list.filter(e => e.beneficiary_id ? e.beneficiary?.name?.toLowerCase().includes(name) : detail_info?.name?.toLowerCase().includes(name));
    //     setShcudle(filtrer)
    // }

    // useEffect(() => {
    //     if (Entername) {
    //         handlesearch();
    //     } else {
    //         setShcudle(ALlData_list);
    //     }
    // }, [Entername])


    const columns = [

        { field: 's_no', headerName: 'S.No', width: 70 },
        {
            field: 'name',
            headerName: 'Name',
            width: 120,
            // valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
            renderCell: (params)=>(
                <>
                  {
                    <p style={{cursor:'pointer'}} onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row?.purchase_transaction)}>{params?.row?.beneficiary?.name ? params?.beneficiary?.name : detail_info?.name}</p>
                  }
                </>
            )
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 100,
            valueGetter: (value, row) => `${row.purchase_transaction?.reduce((accumulator, current) => accumulator + current?.gold_gram, 0)}(gm)` ?? '0',
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            valueGetter: (value, row) => `₹${row.purchase_transaction?.filter(e => e.status == 'paid').reduce((accumulator, current) => accumulator + current?.amount, 0)}` ?? '0',
        },
        {
            field: 'purchase_Date',
            headerName: 'Purchase Date',
            width: 150,
            valueGetter: (value, row) => moment(row.purchase_transaction[0]?.paid_date).format('DD-MM-YYYY') ?? '-',
        },
        {
            field: 'withdraw',
            headerName: 'Withdraw',
            width: 120,
            valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
            sortable: false,
        },
        {

            field: 'withdraw_date',
            headerName: 'Withdraw Date',
            width: 140,
            valueGetter: (value, row) => `${row?.withdraw ? row?.withdraw : '-'}`,
            sortable: false,
        },
        {
            field: 'view',
            headerName: 'View',
            width: 60,
            // valueGetter: (value, row) => `<img alt="" src="\assets\iocns\eye.png" onClick={() => row?.cancel_status == 1 ? {} : setTranscation(row?.purchase_transaction)} className="img-fluid" />`,
            renderCell: (params) => (
                <img
                    src="\assets\iocns\eye.png"
                    onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row?.purchase_transaction)}
                    alt=""
                    width={20}
                    style={{ cursor: 'pointer' }}

                />
            ),
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 105,
            renderCell: (params) => (
                <>
                    {
                        // moment(params?.row?.purchase_transaction[0]?.paid_date, "YYYY-MM-DD").add(3, 'M').month() == moment(new Date()).month() ?
                        currentDate >= addMonths(new Date(params?.row?.purchase_transaction[0]?.paid_date), 3) ?
                            <button type="button" className="btn btn-danger text-white" disabled={params?.row?.cancel_status == 1} onClick={() => { setcancell(params?.row?.purchase_transaction[0]?.purchase_id); document.getElementById('cancelpopmsg').click() }}>{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button> :
                            params?.row?.purchase_status == 1 ?
                                <button type="button" disabled className="btn btn-success text-white">Purchased</button> :
                                <button type="button" disabled className="btn btn-secondary text-white" >{params?.row?.cancel_status == 1 ? "Cancelled" : "Cancel"}</button>

                    }
                </>
            ),
            sortable: false,
        }
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    const handlespinner = (data) => {
        setspinid(data?.id)
        setspinner(true)
    }



    return (
        <>
            <HeadingBanner title={isLoading ? 'Loading...' : settings ? settings[0]?.saving_plan : 'Scheme'} />
            <section>
                <div className="container">
                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-3 col-lg-5"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-9 col-lg-7">
                                <div className="siptrans-content">
                                    <div className="siptrans-head pb-3">
                                        {
                                            isLoading ? <h4><Shimmer className="rounded" width={180} height={30} /></h4> :
                                                <h4>  {transcation?.length > 0 ? <img src="/assets/iocns/Back.png" className="cursor_pointer" onClick={() => setTranscation([])} style={{ width: '30px' }} /> : ''} {Shcudle[0]?.saving_plan?.name ? Shcudle[0]?.saving_plan?.name : plandetails?.name}</h4>
                                        }

                                    </div>


                                    {
                                        transcation?.length > 0 ?
                                            <>
                                                <div className="row">
                                                    {
                                                        transcation.map((data, i) => (
                                                            <div className="col-sm-6 mb-3" key={i}>
                                                                {/* {/ <div className={`plan1 p-3 sp${(i+1) > 6 ? (transcation?.length - i) : i+1} border rounded d-flex align-items-center justify-content-between`}> /} */}
                                                                <div className={`plan1 p-3 sp7 border rounded d-flex align-items-center justify-content-between`}>
                                                                    <div className="plan-time ">
                                                                        {
                                                                            data?.status == "paid" ?
                                                                                <p className="text-wrap"> <span className="fw600">Paid Date :</span> ({moment(data?.paid_date).format('DD-MM-YYYY')})</p>
                                                                                : <p className={"text-wrap"}> <span className="fw600">Schedule Date :</span> ({moment(data?.schedule_date).format('DD-MM-YYYY')})</p>
                                                                        }
                                                                        <p className={" text-wrap mb-1"}><span className="fw600">Transaction Id :</span> {data?.transaction_id ?? '-'}</p>
                                                                        {/* {
                                                                     data?.admin_paid == 1 ?
                                                                     <span class="badge text-bg-danger">ADMIN CONTRIBUTION</span> : ''
                                                                } */}
                                                                    </div>

                                                                    <div className="plan-rate ">
                                                                        {/* {data?.admin_paid == 1 && moment(data?.schedule_date, "YYYY-MM-DD").month() == moment(new Date()).month() && moment(data?.schedule_date, "YYYY-MM-DD").year() == moment(new Date()).year() ?
                                                                            <span class="badge text-bg-danger">ADMIN CONTRIBUTION</span> :
                                                                            data?.status == "unpaid" && moment(data?.schedule_date, "YYYY-MM-DD").month() == moment(new Date()).month() && moment(data?.schedule_date, "YYYY-MM-DD").year() == moment(new Date()).year() ?
                                                                                <button type="button" onClick={() => { setShowRazorpayModal(true); handlepayModal() }} className="btn paynsd">PAY NOW</button> : data?.status == "paid" ?
                                                                                    <img src="\assets\iocns\invoice.png" onClick={() => handledownload(data)} className="ytjsfvdjd" /> :
                                                                                    <button type="button" disabled className="btn btn-warning">UNPAID</button>
                                                                        } */}
                                                                        {data?.admin_paid == 1 ?
                                                                            <span className="badge text-bg-danger">ADMIN PAY</span> :
                                                                            data?.status == "unpaid" && moment(new Date()).month() >= moment(data?.schedule_date, "YYYY-MM-DD").month() && moment(new Date()).year() >= moment(data?.schedule_date, "YYYY-MM-DD").year() ?
                                                                                <button type="button" onClick={() => { setShowRazorpayModal(true); handlepayModal(data) }} className="btn paynsd">PAY NOW</button> : data?.status == "paid" ?
                                                                                    spinner && data?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img src="\assets\iocns\invoice.png" onClick={() => { handledownload(data); handlespinner(data) }} className="ytjsfvdjd" />)
                                                                                    :
                                                                                    <button type="button" disabled className="btn btn-warning">UNPAID</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                            </>
                                            :
                                            <>
                                                {
                                                    isLoading ?
                                                        (


                                                            <>
                                                                <div className="row">
                                                                    <div className="cox-xl-33">
                                                                        <div className="">
                                                                         <Shimmer width={175} height={100} className="rounded"/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cox-xl-33">
                                                                    <div className="">
                                                                         <Shimmer width={175} height={100} className="rounded"/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cox-xl-33">
                                                                    <div className="">
                                                                         <Shimmer width={175} height={100} className="rounded"/>
                                                                        </div>

                                                                    </div>
                                                                    <div className="cox-xl-33">
                                                                    <div className="">
                                                                         <Shimmer width={175} height={100} className="rounded"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="cox-xl-33">
                                                                    <div className="">
                                                                         <Shimmer width={175} height={100} className="rounded"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="siptrans-table border mt-4" >
                                                                    <div className="siptable-view">
                                                                        <table className="table">
                                                                            <thead className="p-3">
                                                                                <tr className="text-center">
                                                                                    <th scope="col" >S.NO</th>
                                                                                    <th scope="col" >Name</th>
                                                                                    <th scope="col">Weight</th>
                                                                                    <th scope="col">Total</th>
                                                                                    <th scope="col">Purchase</th>
                                                                                    <th scope="col">withdraw</th>
                                                                                    <th scope="col">withdraw Date</th>
                                                                                    <th scope="col">View</th>
                                                                                    <th scope="col">Status</th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                {
                                                                                    Array.from({ length: 6 }).map((_, i) => {
                                                                                        return (
                                                                                            <tr className="text-center" key={i}>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block ms-2" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                                                <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>

                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>

                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )


                                                        :

                                                        statuslist?.active > 0 ? (
                                                            <>
                                                                <div className="row">
                                                                    <div className="cox-xl-33">
                                                                        <div className="plan-viewcard comp status2" onClick={() => { handleGetSchema(1) }}>
                                                                            <div className="plan-count">
                                                                                <h4>Actived</h4>
                                                                                <h2>{statuslist?.active ? statuslist?.active : 0}</h2>
                                                                            </div>
                                                                            <div className="icon-position">
                                                                                <BookmarkCheckFill />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cox-xl-33">
                                                                        <div className="plan-viewcard comp status1" onClick={() => { handleGetSchema(3) }}>

                                                                            <div className="plan-count ">
                                                                                <h4>Completed</h4>
                                                                                <h2>{statuslist?.complete ? statuslist?.complete : 0}</h2>
                                                                            </div>
                                                                            <div className="icon-position">
                                                                                <BookmarkStarFill />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cox-xl-33">

                                                                        <div className="plan-viewcard comp status3" onClick={() => { handleGetSchema(4) }}>
                                                                            <div className="plan-count">
                                                                                <h4>Hold</h4>
                                                                                <h2>{statuslist?.hold ? statuslist?.hold : 0}</h2>
                                                                            </div>
                                                                            <div className="icon-position">
                                                                                <PauseCircleFill />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="cox-xl-33">
                                                                        <div className="plan-viewcard comp status4" onClick={() => { handleGetSchema(2) }}>
                                                                            <div className="plan-count">
                                                                                <h4>Cancelled</h4>
                                                                                <h2>{statuslist?.cancel ? statuslist?.cancel : 0}</h2>
                                                                            </div>
                                                                            <div className="icon-position">
                                                                                <BookmarkXFill />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="cox-xl-33">
                                                                        <div className="plan-viewcard comp status5" onClick={() => { handleGetSchema(5) }}>
                                                                            <div className="plan-count">
                                                                                <h4>Suspended</h4>
                                                                                <h2>{statuslist?.suspend ? statuslist?.suspend : 0}</h2>
                                                                            </div>
                                                                            <div className="icon-position">
                                                                                <SlashCircleFill />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <Paper sx={{ height: 450, width: '100%' }} className="tabledata">
                                                                    <DataGrid
                                                                        rows={ALlData_list.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                                        columns={columns}
                                                                        disableColumnMenu
                                                                        initialState={{ pagination: { paginationModel } }}
                                                                        pageSizeOptions={[5, 10]}
                                                                        sx={{ border: 0, marginTop: 3 }}
                                                                        disableColumnFilter
                                                                        disableColumnSelector
                                                                        disableDensitySelector

                                                                        className="schemedata-table"
                                                                        slots={{ toolbar: GridToolbar }}
                                                                        slotProps={{
                                                                            toolbar: {
                                                                                showQuickFilter: true,
                                                                                printOptions: { disableToolbarButton: true },
                                                                                csvOptions: { disableToolbarButton: true },
                                                                                ShowCellToolTip: false

                                                                            },
                                                                        }}
                                                                    />
                                                                </Paper>


                                                            </>


                                                        )

                                                            : (<div className="addbank-ac text-center border py-3">
                                                                <div className="bankimage my-3  ">
                                                                    <img src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                                </div>
                                                                <div className="addbank-info ">
                                                                    <h5>{settings ? settings[0]?.saving_plan : ''}</h5>
                                                                </div>
                                                                <div className="addbank-btn text-white my-3">
                                                                    <button className="text-white" onClick={() => { router('/scheme') }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                                </div>
                                                            </div>)
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="cancelboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#cancelboxmsg" id="cancelpopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <h5 className="modal-title" id="exampleModalLongTitle">{settings ? settings[0]?.digital_plan : ''}</h5>
                        </div>
                        <div className="modal-body  text-center">
                            <div className="pop-cancel-icon">
                                <img src="\assets\iocns\cross mark.png" className="img-fluid" alt="" />
                            </div>
                            <div className="pop-submsg pt-2">
                                <h4>Are you sure you want to cancel? </h4>
                            </div>
                            <div className="mt-3">
                                <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                    I agree <a href={`pages/${cancel_slug ? cancel_slug : ''}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                </label>
                            </div>
                            <div className="modal-footer border-0 justify-content-center ">
                                <button type="button " className="btn bg-danger text-white" onClick={() => { handlecancel() }} >Cancel</button>
                                <button type="button " className="btn bg-secondary text-white" data-dismiss="modal">Close</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
