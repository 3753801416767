import React, { useState, useEffect, useContext } from "react"
import { ArrowRight } from 'react-bootstrap-icons'
import { reference_fun, role_url } from '../../api/Controllapi'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Signup_fun } from "../../api/Controllapi";
import instance from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import { Footerpages } from "../../context/Footerpages";
import Select from 'react-select'
import { Shimmer } from "react-shimmer";
import DatePicker from "react-datepicker";
import moment from "moment";
// import { DatePicker } from '@mui/x-date-pickers'
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Signup = () => {


    // const [usersignup, setusersignup] = useState({
    //     'user_type': '',
    //     'first_name': '',
    //     'last_name': '',
    //     'phone_number': '',
    //     'email': '',
    //     'password': '',
    //     'password_confirmation': '',
    //     'referral_id': ''
    // })

    const [user_role, setuser_role] = useState([])
    const [spinner, setspinner] = useState(false)
    const router = useNavigate()
    const [check, setCheck] = useState(false)
    const { footerpage } = useContext(Footerpages)
    const [refer_data, setrefer_data] = useState([])
    const [isloading, setisloading] = useState(true)
    const [date, setdate] = useState()

    let initValue = {
        'role': '',
        'first_name': '',
        'last_name': '',
        'phone_number': '',
        'email': '',
        'password': '',
        'password_confirmation': '',
        'reference_id': '',
        'date_of_birth': ''
    }
    // const termsvalue = footerpages.slice(0,2)

    // console.log("ter",termsvalue)


    const signschema = Yup.object().shape({

        'role': Yup.string().required('Required'),

        'first_name': Yup.string()
            .required('Enter Your First Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        'last_name': Yup.string()
            .required('Enter Your Last Name')
            .matches(/^[a-zA-Z ]*$/, "Invalid name"),

        'phone_number': Yup.string()
            .min(10, 'min 10 number')
            .max(10, 'max 10 number')
            .required('Enter Your Mobile Number')
            .matches(/^[0-9]+$/, "Invalid  Phone Number"),

        'email': Yup.string()
            .email('Invalid Email')
            .required('Enter Your Email')
            .test(value => {
                return value === value.toLocaleLowerCase()
            }),

        "password": Yup.string().required("Enter your password ")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
               "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character"
            ),

        "password_confirmation": Yup.string().required("Enter Confirm Password")
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),

        'reference_id': Yup.date().nullable()
            .required('Select Your Reference ID'),

        // : Yup.date()
        //     .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
        //     .required("Enter DOB"),

        'date_of_birth': Yup.string()
            .required('Enter Your DOB'),
    })


    const Formrik = useFormik({
        initialValues: initValue,
        validationSchema: signschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            setspinner(true)

            // if (!value.reference_id) {
            //     return toast.error('Select Referal')
            // }
            if (check) {
                await Signup_fun(value)
                    .then((res) => {
                        console.log(res)
                        document.getElementById('closesignmodal')?.click()
                        resetForm()
                        setspinner(false)
                    })
                    .catch((err) => {
                        setspinner(false)
                        if (err.message === "Request failed with status code 400") {
                            toast.error(err?.response?.data?.email)
                        }
                        console.log(err)
                    })
            } else {
                toast.error('Please Agree Terms & conditions')
                setspinner(false)
            }
        }
    }
    )

    const getrole_api = async () => {

        try {
            const response = await instance.get(`${role_url}`)
            setuser_role(response?.data?.data)

            Formrik.setFieldValue('role', response?.data?.data?.length > 0 ? response?.data?.data[0]?.id : '');
            setisloading(false)
        }
        catch (err) {
            console.log(err)
            setisloading(false)
        }
    }

    const getrefer_api = () => {
        reference_fun()
            .then((res) => {
                setrefer_data(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getrefer_api()
        getrole_api()
    }, [])

 

    return (
        <>
            <div className="modal p-0 fade signupmodal" id="signupmodalview" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg px-lg-5" >
                    <div className="modal-content px-4 py-2">
                        <div className="modal-header login-header justify-content-center border-0">
                            <button className="d-none" id="closesignmodal" data-bs-toggle="modal" data-bs-target="#loginmodalview" ></button>
                            <button type="button" className="d-none" id="closesignmodaltermspage" data-bs-dismiss="modal" aria-label="Close"></button>
                            <h3 className="modal-title">Create an Account</h3>
                        </div>

                        <div className="modal-body login-body login-text">

                            <form onSubmit={Formrik.handleSubmit}>

                                <div className="select-identity">

                                    <div className="row check">

                                        {
                                            isloading ? Array.from({ length: 2 }).map((_, i) => {
                                                return (
                                                    <div className="col-lg-6" key={i}><div ><Shimmer className="rounded" width={298} height={48} /></div></div>
                                                )
                                            }) : user_role ? user_role.map((role, i) => {
                                                return (
                                                    <div className="col-lg-6" key={i}>
                                                        <div className = {role.id == Formrik.getFieldProps('role').value  ? "form-check check-active my-2" : "form-check check-unactive my-2" }  >
                                                            <input className="form-check-input cust" type="radio" name="flexRadioDefault" checked={Formrik.getFieldProps('role').value == role.id} value={role.id} id={role.name} onChange={(e) => { Formrik.setFieldValue('role', role.id) }} />
                                                            <label className="form-check-label" htmlFor={role.name} >
                                                                {role.name}
                                                            </label>
                                                        </div>
                                                        {Formrik.touched.role && Formrik.errors.role ? (
                                                            <span className="text-danger">{Formrik.errors.role}</span>
                                                        ) : null}
                                                    </div>
                                                )
                                            }) : ""
                                        }



                                    </div>
                                </div>

                                <div className="signup-detailform">
                                    <div className="row mb-3">
                                        <div className="col-lg-6 py-2">
                                            <label>First Name</label>
                                            <input type="text" name="firstName" placeholder="First name" className="form-control" {...Formrik.getFieldProps('first_name')} />
                                            {Formrik.touched.first_name && Formrik.errors.first_name ? (
                                                <span className="text-danger">{Formrik.errors.first_name}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 py-2">
                                            <label>Last Name</label>
                                            <input type="text" name="lastName" placeholder="Last name" className="form-control" {...Formrik.getFieldProps('last_name')} />
                                            {Formrik.touched.last_name && Formrik.errors.last_name ? (
                                                <span className="text-danger">{Formrik.errors.last_name}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 py-2">
                                            <label>Phone Number</label>
                                            <input name="Phone" placeholder="Phone number" className="form-control" {...Formrik.getFieldProps('phone_number')} />
                                            {Formrik.touched.phone_number && Formrik.errors.phone_number ? (
                                                <span className="text-danger">{Formrik.errors.phone_number}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-6 py-2">
                                            <label>DOB</label>

                                            <div>
                                                <DatePicker
                                                    selected={date}
                                                    minDate={new Date(moment().subtract(150, "years"))} 
                                                    maxDate={new Date(moment().subtract(18, "years"))} 
                                                    dateFormat="dd/MM/yyyy"
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    placeholderText="DD-MM-YYYY"
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onChange={(date) => { setdate(date); Formrik.setFieldValue('date_of_birth', moment(date).format('YYYY-MM-DD')) }}

                                                />
                                                {
                                                    Formrik.touched.date_of_birth && Formrik.errors.date_of_birth ? (<span className="text-danger">{Formrik.errors.date_of_birth}</span>) : null
                                                }
                                            </div>

                                        </div>



                                        <div className="col-lg-6 py-2">
                                            <label>Email</label>
                                            <input type="text" name="email" placeholder="Email" className="form-control" {...Formrik.getFieldProps('email')} />
                                            {Formrik.touched.email && Formrik.errors.email ? (
                                                <span className="text-danger">{Formrik.errors.email}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 py-2">
                                            <label>Reference ID</label>
                                            {/* <select className="form-select" id="inputGroupSelect01" {...Formrik.getFieldProps("reference_id")} >
                                                    <option value=''>Reference ID</option>
                                                    {
                                                        refer_data ? refer_data?.map((data, i) => {
                                                            return (
                                                                <option value={data?.id}>{data?.code}</option>
                                                            )
                                                        }) : ''
                                                    }

                                                </select>
                                                {
                                                    Formrik.touched.reference_id && Formrik.errors.reference_id ? (<span className="text-danger">{Formrik.errors.reference_id}</span>) : null
                                                } */}

                                            <Select
                                                options={refer_data ?? []}
                                                getOptionValue={(e) => e?.id}
                                                getOptionLabel={(e) => e?.code}
                                                className=""
                                                onChange={(e) => {
                                                    Formrik.setFieldValue('reference_id', e?.id)
                                                }}
                                                classNamePrefix={'select'}
                                            />

                                            {
                                                Formrik.touched.reference_id && Formrik.errors.reference_id ? (<span className="text-danger">{Formrik.errors.reference_id}</span>) : null
                                            }

                                        </div>

                                        <div className="col-lg-6 py-2">
                                            <label>Password</label>
                                            <input type="password" name="password" placeholder="Password" className="form-control" {...Formrik.getFieldProps('password')} />
                                            {Formrik.touched.password && Formrik.errors.password ? (
                                                <span className="text-danger">{Formrik.errors.password}</span>
                                            ) : null}
                                        </div>

                                        <div className="col-lg-6 py-2">
                                            <label>Confirm Password</label>
                                            <input type="password" name="password_confirmation" placeholder="Confirm password" className="form-control" {...Formrik.getFieldProps('password_confirmation')} />
                                            {Formrik.touched.password_confirmation && Formrik.errors.password_confirmation ? (
                                                <span className="text-danger">{Formrik.errors.password_confirmation}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-check mb-3">

                                    <input className="form-check-input" onChange={(e) => setCheck(e.target.checked)} type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label login-terms ms-2" htmlFor="flexCheckDefault">
                                        {
                                            footerpage ? (<h6>By Signing In, I agree to  <a href={`pages/${footerpage[0]?.slug}`} target="_blank"><span>Terms and Condition</span></a> and  <a href={`pages/${footerpage[1]?.slug}`} target="_blank"><span>Privacy Policy</span></a></h6>)
                                                : (<h6>By Signing In, I agree to Terms and Condition and Privacy Policy </h6>)
                                        }

                                    </label>
                                </div>

                                <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Submit'}</button>
                                <div className="login-footer"> <h4 className="pt-4 text-center">Already have an account <span className="go-signup" data-bs-toggle="modal" data-bs-target="#loginmodalview">Login</span> <span className="signarrow" data-bs-toggle="modal" data-bs-target="#loginmodalview" ><ArrowRight /></span></h4></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Signup;